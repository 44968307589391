import React, { useState, useEffect, useRef } from "react"
import { isEqual } from "lodash"
import styled from "styled-components"
import moment from "moment-timezone"
import { useQueryClient } from "react-query"
import {
	Alert,
	Avatar,
	DatePicker,
	Drawer,
	Dropdown,
	Form,
	Input,
	InputNumber,
	Menu,
	Modal,
	Select,
	Collapse,
	Row,
	Col,
	TimePicker,
	Tooltip,
	Upload,
	Space
} from "antd"
import { InfoCircleFilled } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import {
	FIELD_NAMES,
	LABEL_NAMES,
	EPISODE_TYPE_OPTIONS,
	TRAILER_BONUS_TYPE_OPTIONS,
	PARENTAL_ADVISORY_OPTIONS,
	ITUNES_VISIBILITY_OPTIONS,
	NOT_PUBLISHED,
	ARTWORK_OPTIONS,
	ARTWORK_REQUIRED,
	TITLE_REQUIRED,
	TITLE_TOO_LONG,
	SEASON_NUMBER_REQUIRED,
	INVALID_SEASON_NUMBER,
	EPISODE_NUMBER_REQUIRED,
	INVALID_EPISODE_NUMBER,
	LABEL_TOO_LONG,
	TOO_MANY_LABELS,
	PHOTO_TOO_LARGE_ERROR_MESSAGE,
	EPISODE_ALREADY_PUBLISHED_ALERT,
	UNABLE_TO_UPLOAD_RECORDING,
	SUCCESSFULLY_CREATED_RECORDING,
	SUCCESSFULLY_REMOVED_RECORDING,
	SUCCESSFULLY_REPLACED_RECORDING,
	RECORDING_UPLOADED_TIME_DISPLAY_FORMAT,
	UNABLE_TO_CREATE_RECORDING,
	UNABLE_TO_REMOVE_RECORDING,
	UNABLE_TO_REPLACE_RECORDING
} from "constants/strings/Episodes"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import {
	toggleAddNewEpisodeDrawer,
	toggleAddNewEpisodeConfirmationModalVisible,
	createNewEpisode,
	createNewEpisodeSuccess,
	createNewEpisodeError,
	setEpisodeIdForConfirmationModal,
	setEpisodeIdForInitialValues,
	setEpisodeLastPublishedDate
} from "redux/actions/Episodes/AddNewEpisode"
import {
	setEpisodeIdForLastPublishedDateModal,
	toggleChangeLastPublishedDateModalVisible
} from "redux/actions/Episodes"
import { toggleChooseRecordingModalVisible, setConfigForChooseRecordingModal } from "redux/actions/Recordings"
import useToggle from "hooks/useToggle"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalRadioButtonGroup from "components/common/Buttons/PodpalRadioGroup"
import ImageUpload from "components/common/Uploads/ImageUpload"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import ImgCrop from "antd-img-crop"
import emptyPodcastArtworkImage from "assets/podcasts/emptypodcastartwork.svg"
import { keysToCamel } from "utils/camelCaseUtils"
import { findEpisodeById, updateEpisode } from "services/episode.service"
import { useEpisodeRecording } from "services/queries/Recordings"
import {
	createRecording,
	replaceRecording,
	updateRecording,
	validateRecordingFileName
} from "services/recording.service"
import { duplicateFilenameModal, renameFileModal } from "components/common/Modals/Episodes/EpisodeManagementModals"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { formatTime } from "utils/time"
import { getBase64 } from "utils/b64"
import { uploadFile, getSignedUrl } from "services/upload.service"
import UploadRecordingCard from "components/common/Cards/UploadRecordingCard"
import "./NewEpisodeFormDrawer.scss"
import { v4 as uuidv4 } from "uuid"
import { useDownload } from "hooks/useDownload"
import { mapFileTypeToExtension } from "utils/recording"

const { Panel } = Collapse

const StyledText = styled.p`
	font: ${props => props.theme.fontXs};
	color: ${props => props.theme.textSecondary};
`

const NewEpisodeFormDrawer = () => {
	const initialValuesEpisodeId = useSelector(state => state.addNewEpisode.initialValuesEpisodeId)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const dispatch = useDispatch()
	const [episode, setEpisode] = useState(null)
	const [recording, setRecording] = useState(null)
	const [episodeProgress, setEpisodeProgress] = useState(null)

	const { data: recordingData, isSuccess: recordingSuccess } = useEpisodeRecording(initialValuesEpisodeId)
	const { data: episodeProgressData } = useEpisodeProgress(initialValuesEpisodeId)

	useEffect(() => {
		if (recordingData?.recording && recordingSuccess) {
			setRecording(recordingData.recording)
		} else {
			setRecording(null)
		}
	}, [recordingData, recordingSuccess])

	useEffect(() => {
		if (episodeProgressData?.episodeProgress) {
			setEpisodeProgress(episodeProgressData.episodeProgress)
		} else {
			setEpisodeProgress(null)
		}
	}, [episodeProgressData])

	useEffect(() => {
		const fetchEpisodeData = async () => {
			const { data } = await findEpisodeById(initialValuesEpisodeId)
			// TODO: refactor this to use useQuery hook
			return { data: { request_id: data.request_id, episode: data.episodes[0] } }
		}

		if (initialValuesEpisodeId) {
			fetchEpisodeData()
				.then(res => {
					const episodeCamelKeys = keysToCamel(res.data.episode)
					episodeCamelKeys[FIELD_NAMES.TRAILER_BONUS_TYPE] = episodeCamelKeys?.trailerType?.split("_")[0]
					episodeCamelKeys[FIELD_NAMES.LABEL] = episodeCamelKeys[FIELD_NAMES.LABEL]
						? [episodeCamelKeys[FIELD_NAMES.LABEL]]
						: []
					episodeCamelKeys[FIELD_NAMES.EPISODE_PARENTAL_ADVISORY] =
						episodeCamelKeys[FIELD_NAMES.EPISODE_PARENTAL_ADVISORY] ?? selectedPodcast?.parentalAdvisory
					episodeCamelKeys[FIELD_NAMES.EPISODE_TYPE] =
						episodeCamelKeys[FIELD_NAMES.EPISODE_TYPE] ?? EPISODE_TYPE_OPTIONS.FULL
					episodeCamelKeys[FIELD_NAMES.TRAILER_BONUS_TYPE] =
						episodeCamelKeys[FIELD_NAMES.TRAILER_BONUS_TYPE] ?? TRAILER_BONUS_TYPE_OPTIONS.SHOW
					episodeCamelKeys[FIELD_NAMES.ITUNES_VISIBILITY] =
						episodeCamelKeys[FIELD_NAMES.ITUNES_VISIBILITY] ?? ITUNES_VISIBILITY_OPTIONS.SHOW
					setEpisode(episodeCamelKeys)
				})
				.catch(e => {
					console.error(e)
				})
		} else {
			setEpisode({
				[FIELD_NAMES.PHOTO]: selectedPodcast?.photoUrl?.length > 0 ? selectedPodcast.photoUrl : DEFAULT_PODCAST_ARTWORK_URL,
				[FIELD_NAMES.EPISODE_PARENTAL_ADVISORY]: selectedPodcast?.parentalAdvisory,
				[FIELD_NAMES.EPISODE_TYPE]: EPISODE_TYPE_OPTIONS.FULL,
				[FIELD_NAMES.TRAILER_BONUS_TYPE]: TRAILER_BONUS_TYPE_OPTIONS.SHOW,
				[FIELD_NAMES.ITUNES_VISIBILITY]: ITUNES_VISIBILITY_OPTIONS.SHOW
			})
		}
	}, [dispatch, initialValuesEpisodeId, selectedPodcast])

	return (
		<NewEpisodeFormDrawerComponent
			initialValues={episode}
			recordingData={recording}
			episodeProgressData={episodeProgress}
			isEditDrawer={initialValuesEpisodeId ? true : false}
		/>
	)
}

const NewEpisodeFormDrawerComponent = ({ initialValues, recordingData, episodeProgressData, isEditDrawer }) => {
	const dispatch = useDispatch()

	const [form] = Form.useForm()
	const queryClient = useQueryClient()

	const visible = useSelector(state => state.addNewEpisode.addNewEpisodeDrawerVisible)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const size = useWindowSize()
	const isMobile = size.width < BREAKPOINT_XS

	const { handleDownload } = useDownload()

	const [submitLoading, toggleSubmitLoading] = useToggle()
	const [artworkType, setArtworkType] = useState(ARTWORK_OPTIONS.DEFAULT)

	const defaultPodcastArtwork =
		selectedPodcast?.photoUrl?.length > 0 ? selectedPodcast.photoUrl : emptyPodcastArtworkImage
	const [episodeArtworkImage, setEpisodeArtworkImage] = useState(null)
	const [artworkBlob, setArtworkBlob] = useState(null)

	const [seasonsDisabled, setSeasonsDisabled] = useState(false)
	const [episodesDisabled, setEpisodesDisabled] = useState(false)

	const [releaseDate, setReleaseDate] = useState(null)
	const [releaseTime, setReleaseTime] = useState(null)

	const lastPublishedDate = useSelector(state => state.addNewEpisode.lastPublishedDate)
	const [lastPublishedDateFormatted, setLastPublishedDateFormatted] = useState(NOT_PUBLISHED)

	const [episodeUrl, setEpisodeUrl] = useState(null)
	const [episodePublished, setEpisodePublished] = useState(initialValues?.isPublished)

	const [uploadConfig, setUploadConfig] = useState(null)
	const [filename, setFilename] = useState("")
	const [progress, setProgress] = useState(0)
	const [loading, setLoading] = useState(false)
	const [media, setMedia] = useState("")
	const [mediaDuration, setMediaDuration] = useState(null)
	const [recording, setRecording] = useState({})
	const [recordingKey, setRecordingKey] = useState(null)
	const [isRemoved, setIsRemoved] = useState(false)
	const [isReplacingRecording, setIsReplacingRecording] = useState(false) // Set to true if the "Replace" button with the dropdown is being used.
	const [duplicateRecordingID, setDuplicateRecordingID] = useState(null) // Set to true if a duplicate recording is being replaced.

	const [episodeModified, setEpisodeModified] = useState(false)

	const uploadCancelTokenRef = useRef(null)

	useEffect(() => {
		form.resetFields()
		setTrailerButtonsDisabled(initialValues?.episodeType === EPISODE_TYPE_OPTIONS.FULL)
		if (initialValues?.episodeType) {
			setSeasonTrailerDisabled(
				initialValues.episodeType !== EPISODE_TYPE_OPTIONS.FULL && selectedPodcast?.episodeGrouping === "none"
			)
			setEpisodeTrailerDisabled(
				initialValues.episodeType !== EPISODE_TYPE_OPTIONS.FULL && selectedPodcast?.episodeNumbering === "no"
			)
		}
		if (initialValues?.photoUrl && isEditDrawer) {
			if (initialValues.photoUrl !== selectedPodcast?.photoUrl && initialValues.photoUrl !== DEFAULT_PODCAST_ARTWORK_URL) {
				setEpisodeArtworkImage(initialValues.photoUrl)
				setArtworkType(ARTWORK_OPTIONS.CUSTOM)
			}
		}

		if (initialValues?.scheduledReleaseDate) {
			const date = moment(initialValues.scheduledReleaseDate)
			setReleaseDate(date)
			setReleaseTime(date)
		}

		setEpisodeUrl(
			initialValues?.isPublished
				? `${process.env.REACT_APP_PAPP_URL}/pod/${selectedPodcast.profileUrl}/${initialValues.id}`
				: NOT_PUBLISHED
		)
		if (initialValues?.isPublished) {
			dispatch(setEpisodeLastPublishedDate(moment(initialValues.lastPublishedDate)))
		}

		setEpisodePublished(initialValues?.isPublished)
	}, [dispatch, initialValues, form, selectedPodcast, isEditDrawer])

	// Effect handles recording data handling in the drawer for multiple cases (removed, uploaded and not saved, saved, and replaced.)
	useEffect(() => {
		// Episode is already created.
		if (isEditDrawer) {
			if (recordingData && !isRemoved && !isReplacingRecording) {
				setMedia(recordingData?.url)
				setMediaDuration(formatTime(recordingData?.duration))
				setRecording(recordingData)
				setRecordingKey(recordingData?.id)
			} else if (!recording) {
				setMedia(null)
				setMediaDuration(null)
			}
		} else {
			setMedia(media)
			setMediaDuration(mediaDuration)
		}
	}, [isEditDrawer, isRemoved, isReplacingRecording, media, mediaDuration, recording, recordingData])

	useEffect(() => {
		if (selectedPodcast) {
			setSeasonsDisabled(selectedPodcast.episodeGrouping === "none")
			setEpisodesDisabled(selectedPodcast.episodeNumbering === "no")
		}
	}, [selectedPodcast])

	useEffect(() => {
		if (initialValues?.isPublished) {
			setLastPublishedDateFormatted(
				moment(lastPublishedDate).format("M/D/YYYY [at] h:mm A ") + moment.tz(moment.tz.guess()).format("z")
			)
		} else {
			setLastPublishedDateFormatted(NOT_PUBLISHED)
		}
	}, [initialValues, lastPublishedDate])

	const episodeArtworkRadioButtons = [
		{ label: "Use Podcast Artwork (default)", value: ARTWORK_OPTIONS.DEFAULT },
		{ label: "Replace with Custom Artwork", value: ARTWORK_OPTIONS.CUSTOM }
	]

	const episodeTypeRadioButtons = [
		{ label: "Full", value: EPISODE_TYPE_OPTIONS.FULL },
		{ label: "Trailer", value: EPISODE_TYPE_OPTIONS.TRAILER },
		{ label: "Bonus", value: EPISODE_TYPE_OPTIONS.BONUS }
	]

	const [seasonTrailerDisabled, setSeasonTrailerDisabled] = useState(false)
	const [episodeTrailerDisabled, setEpisodeTrailerDisabled] = useState(false)

	const trailerBonusTypeRadioButtons = [
		{ label: "Show", value: TRAILER_BONUS_TYPE_OPTIONS.SHOW },
		{
			label: "Season",
			value: TRAILER_BONUS_TYPE_OPTIONS.SEASON,
			disabled: seasonTrailerDisabled
		},
		{
			label: "Episode",
			value: TRAILER_BONUS_TYPE_OPTIONS.EPISODE,
			disabled: episodeTrailerDisabled
		}
	]

	const episodeParentalAdvisoryRadioButtons = [
		{ label: "Clean", value: PARENTAL_ADVISORY_OPTIONS.CLEAN },
		{ label: "Explicit", value: PARENTAL_ADVISORY_OPTIONS.EXPLICIT }
	]

	const [itunesVisibilityClassname, setItunesVisibilityClassname] = useState("")

	useEffect(() => {
		if (initialValues) {
			setItunesVisibilityClassname(
				initialValues[FIELD_NAMES.ITUNES_VISIBILITY] === ITUNES_VISIBILITY_OPTIONS.HIDE
					? "itunes-visibility-radio-button"
					: ""
			)
		}
	}, [initialValues])

	const itunesVisibilityRadioButtons = [
		{ label: "Show", value: ITUNES_VISIBILITY_OPTIONS.SHOW },
		{ label: "Hide", value: ITUNES_VISIBILITY_OPTIONS.HIDE }
	]

	const [trailerButtonsDisabled, setTrailerButtonsDisabled] = useState(true)
	useEffect(() => {
		if (visible && trailerButtonsDisabled) {
			document
				.querySelector("input[value=SHOW]")
				.parentElement.parentElement.setAttribute("title", "Change episode type to create a trailer or bonus episode.")

			document
				.querySelector("input[value=SEASON]")
				.parentElement.parentElement.setAttribute("title", "Change episode type to create a trailer or bonus episode.")

			document
				.querySelector("input[value=EPISODE]")
				.parentElement.parentElement.setAttribute("title", "Change episode type to create a trailer or bonus episode.")
		} else if (visible && !trailerButtonsDisabled) {
			document.querySelector("input[value=SHOW]").parentElement.parentElement.removeAttribute("title")

			document.querySelector("input[value=SEASON]").parentElement.parentElement.removeAttribute("title")

			document.querySelector("input[value=EPISODE]").parentElement.parentElement.removeAttribute("title")
		}
	}, [visible, trailerButtonsDisabled])

	useEffect(() => {
		if (visible && seasonTrailerDisabled) {
			document
				.querySelector("input[value=SEASON]")
				.parentElement.parentElement.setAttribute(
					"title",
					"Enable Season #'s in Show Settings to create a Season Trailer or Season Bonus."
				)
		}
	}, [visible, seasonTrailerDisabled])

	useEffect(() => {
		if (visible && episodeTrailerDisabled) {
			document
				.querySelector("input[value=EPISODE]")
				.parentElement.parentElement.setAttribute(
					"title",
					"Enable Episode #'s in Show Settings to create an Episode Trailer or Bonus Episode."
				)
		}
	}, [visible, episodeTrailerDisabled])

	useEffect(() => {
		if (!releaseDate || !releaseTime) return
		const datetime = releaseDate
		datetime.set({ hour: releaseTime.get("hour"), minute: releaseTime.get("minute"), seconds: 0 })
		if (!datetime.isSame(initialValues?.plannedReleaseDate)) {
			setEpisodeModified(true)
		}
	}, [form, releaseDate, releaseTime, initialValues])

	const handleValuesChange = (changedValues, allValues) => {
		let mod = false
		for (const [key, value] of Object.entries(changedValues)) {
			if (!isEqual(value, initialValues[key])) mod = true
		}
		setEpisodeModified(mod)

		if (initialValues?.isPublished) {
			setEpisodeModified(true)
		}
		const newEpisodeType = changedValues[FIELD_NAMES.EPISODE_TYPE]
		const newTrailerBonusType = changedValues[FIELD_NAMES.TRAILER_BONUS_TYPE]
		if (newEpisodeType) {
			setTrailerButtonsDisabled(newEpisodeType === EPISODE_TYPE_OPTIONS.FULL)
			setSeasonTrailerDisabled(newEpisodeType !== EPISODE_TYPE_OPTIONS.FULL && selectedPodcast?.episodeGrouping === "none")
			setEpisodeTrailerDisabled(newEpisodeType !== EPISODE_TYPE_OPTIONS.FULL && selectedPodcast?.episodeNumbering === "no")
		}

		if (newEpisodeType || newTrailerBonusType) {
			const isShowTrailerOrBonus =
				(allValues[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.TRAILER &&
					allValues[FIELD_NAMES.TRAILER_BONUS_TYPE] === TRAILER_BONUS_TYPE_OPTIONS.SHOW) ||
				(allValues[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.BONUS &&
					allValues[FIELD_NAMES.TRAILER_BONUS_TYPE] === TRAILER_BONUS_TYPE_OPTIONS.SHOW)

			const isSeasonTrailerOrBonus =
				(allValues[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.TRAILER &&
					allValues[FIELD_NAMES.TRAILER_BONUS_TYPE] === TRAILER_BONUS_TYPE_OPTIONS.SEASON) ||
				(allValues[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.BONUS &&
					allValues[FIELD_NAMES.TRAILER_BONUS_TYPE] === TRAILER_BONUS_TYPE_OPTIONS.SEASON)

			setSeasonsDisabled(isShowTrailerOrBonus || selectedPodcast?.episodeGrouping === "none" ? true : false)
			setEpisodesDisabled(
				isShowTrailerOrBonus || isSeasonTrailerOrBonus || selectedPodcast?.episodeNumbering === "no" ? true : false
			)
		}

		const newItunesVisibility = changedValues[FIELD_NAMES.ITUNES_VISIBILITY]

		if (newItunesVisibility) {
			setItunesVisibilityClassname(
				newItunesVisibility === ITUNES_VISIBILITY_OPTIONS.HIDE ? "itunes-visibility-radio-button" : ""
			)
		}
	}

	const resetRecordingFields = () => {
		setLoading(false)
		setMedia(null)
		setMediaDuration(null)
		setProgress(0)
		setRecording({})
		setRecordingKey(null)
		setDuplicateRecordingID(null)
	}

	const handleClose = () => {
		setArtworkType(ARTWORK_OPTIONS.DEFAULT)
		form.resetFields()
		setEpisodeArtworkImage(null)
		resetRecordingFields()
		setIsRemoved(false)
		setIsReplacingRecording(false)
		setEpisodeModified(false)
		setTrailerButtonsDisabled(form.getFieldValue(FIELD_NAMES.EPISODE_TYPE) === EPISODE_TYPE_OPTIONS.FULL)
		dispatch(setEpisodeIdForInitialValues(null))
		dispatch(setEpisodeLastPublishedDate(null))
		setReleaseDate(null)
		setReleaseTime(null)
		dispatch(toggleAddNewEpisodeDrawer())
		if (uploadCancelTokenRef?.current) {
			uploadCancelTokenRef.current.cancel()
		}
	}

	const validateEpisodeLabel = async (rule, value) => {
		if (value?.length > 0) {
			return value[0].length <= 20 ? Promise.resolve() : Promise.reject(LABEL_TOO_LONG)
		} else {
			return Promise.resolve()
		}
	}

	const readFileAsync = async file => {
		return new Promise(resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = e => {
				const image = new Image()
				image.src = e.target.result
				resolve([image.height, image.width])
			}
		})
	}

	const validateEpisodeArtwork = async () => {
		if (artworkType === ARTWORK_OPTIONS.CUSTOM && !episodeArtworkImage) {
			return Promise.reject(ARTWORK_REQUIRED)
		} else if (artworkType === ARTWORK_OPTIONS.CUSTOM && episodeArtworkImage && artworkBlob !== null) {
			const [height, width] = await readFileAsync(artworkBlob)
			if (height > 3000 && width > 3000) {
				return Promise.reject("Podcast artwork exceeds the maximum dimension limit.")
			} else if (height < 1400 && width < 1400) {
				return Promise.reject("Podcast artwork does not meet the minimum dimension limit.")
			} else if (height > 3000 || width > 3000 || height < 1400 || width < 1400) {
				return Promise.reject("Podcast artwork does not meet dimension limits")
			} else {
				return Promise.resolve()
			}
		} else {
			return Promise.resolve()
		}
	}

	const handleSubmit = async values => {
		if (!isEditDrawer) {
			values.podcastId = selectedPodcast.id
		} else {
			values.podcastId = initialValues.podcastId
			values.id = initialValues.id
		}

		const isCustomArtworkDifferentFromInitial = episodeArtworkImage !== initialValues[FIELD_NAMES.PHOTO]

		const isNewArtwork = episodeArtworkImage && episodeArtworkImage !== initialValues[FIELD_NAMES.PHOTO]

		if (isNewArtwork) {
			// custom artwork has changed
			values["thumbnailFile"] = episodeArtworkImage
			values[FIELD_NAMES.PHOTO] = undefined
		} else if (!isCustomArtworkDifferentFromInitial && artworkType === ARTWORK_OPTIONS.CUSTOM) {
			// custom artwork didn't change - don't change artwork
			values[FIELD_NAMES.PHOTO] = episodeArtworkImage
		} else if (defaultPodcastArtwork === selectedPodcast?.photoUrl) {
			// apply podcast artwork
			values[FIELD_NAMES.PHOTO] = defaultPodcastArtwork
		} else {
			// apply blank (grey) artwork
			values[FIELD_NAMES.PHOTO] = DEFAULT_PODCAST_ARTWORK_URL
		}

		values[FIELD_NAMES.LABEL] = values.label ? values.label[0] : null

		if (!trailerButtonsDisabled) {
			if (values[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.TRAILER) {
				values.trailerType = `${values[FIELD_NAMES.TRAILER_BONUS_TYPE]}_${EPISODE_TYPE_OPTIONS.TRAILER}`
			} else if (values[FIELD_NAMES.EPISODE_TYPE] === EPISODE_TYPE_OPTIONS.BONUS) {
				values.bonusType = `${values[FIELD_NAMES.TRAILER_BONUS_TYPE]}_${EPISODE_TYPE_OPTIONS.BONUS}`
			}
		}
		values[FIELD_NAMES.SEASON_NUMBER] = seasonsDisabled ? null : values[FIELD_NAMES.SEASON_NUMBER]
		values[FIELD_NAMES.EPISODE_NUMBER] = episodesDisabled ? null : values[FIELD_NAMES.EPISODE_NUMBER]

		if (releaseDate && releaseTime) {
			const datetime = releaseDate
			datetime.set({ hour: releaseTime.get("hour"), minute: releaseTime.get("minute"), seconds: 0 })
			values.plannedReleaseDate = datetime.format()
		}

		if (initialValues?.isPublished) {
			values.publishedEpisodeModified = true
		}

		if (!isEditDrawer && !isRemoved && !isReplacingRecording && !duplicateRecordingID) {
			values["recording"] = { ...recording, id: recordingKey }
		}

		if (!isEditDrawer) {
			toggleSubmitLoading()
			dispatch(createNewEpisode(values))
				.then(res => {
					values.id = res.data.episode_id
					setEpisodeModified(false)
					dispatch(createNewEpisodeSuccess(values))
					dispatch(setEpisodeIdForConfirmationModal(res.data.episode_id))
					toggleSubmitLoading()
					handleClose()
					dispatch(toggleAddNewEpisodeConfirmationModalVisible())
					queryClient.invalidateQueries(["episodes", selectedPodcast.id])
					if (values.recording) {
						queryClient.invalidateQueries(["recording", res.data.episode_id])
					}
				})
				.catch(err => {
					dispatch(createNewEpisodeError(err))
					toggleSubmitLoading()
				})
		} else {
			toggleSubmitLoading()
			updateEpisode({ ...initialValues, ...values })
				.then(res => {
					toggleSubmitLoading()
					setEpisodeModified(false)
					handleClose()
					queryClient.invalidateQueries(["episodes", selectedPodcast.id])
					queryClient.invalidateQueries(["episode", values.id])
					if (values.recording) {
						queryClient.invalidateQueries(["recording", values.id])
					}
				})
				.catch(err => {
					dispatch(createNewEpisodeError(err))
					toggleSubmitLoading()
				})
		}
	}

	const checkPhotoFileSize = file => {
		getBase64(file, imageUrl => {
			setEpisodeArtworkImage(imageUrl)
			setEpisodeModified(true)
		})

		return false
	}

	const checkPhotoFileType = (file, fileList) => {
		setArtworkBlob(file)
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
		if (!isJpgOrPng) {
			fileList[0]["response"] = "Invalid file type"
		}

		const isLt2M = file.size / 1024 / 1024 < 16
		if (!isLt2M) {
			Modal.error({
				title: "Sorry, but your file is too big!",
				content: PHOTO_TOO_LARGE_ERROR_MESSAGE,
				centered: true
			})
		}

		return isLt2M
	}

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: episodeProgressData?.episodeProgress?.setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: episodeProgressData?.episodeProgress?.inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: episodeProgressData?.episodeProgress?.scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: episodeProgressData?.episodeProgress?.uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: episodeProgressData?.episodeProgress?.writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: episodeProgressData?.episodeProgress?.reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async progressStep => {
		if (!episodeProgressData?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.COMPLETED
		episodeProgressData.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData, [progressStep]: STATUS.COMPLETED })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleMarkAsInProgress = async progressStep => {
		if (!episodeProgressData?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.IN_PROGRESS
		episodeProgressData.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeId])
		} catch (e) {
			console.error(e)
			errorAlert("Failed to mark step as done.")
		}
	}

	const extractDuration = file => {
		// Create an A/V DOM element to extract duration.
		return new Promise((resolve, reject) => {
			let mediaElement = null
			let fileUrl = URL.createObjectURL(file)
			if (!isVideoPodcast) {
				mediaElement = document.createElement("audio")
				mediaElement.src = fileUrl
				mediaElement.ondurationchange = () => {
					setMediaDuration(mediaElement.duration)
					resolve(mediaElement.duration)
				}
			} else {
				mediaElement = document.createElement("video")
				mediaElement.src = fileUrl
				mediaElement.ondurationchange = () => {
					setMediaDuration(mediaElement.duration)
					resolve(mediaElement.duration)
				}
			}
		})
	}

	const setPublishedEpisodeModified = async () => {
		if (initialValues.isPublished) {
			await updateEpisode({
				...initialValues,
				[FIELD_NAMES.LABEL]: initialValues.label[0] ?? null,
				publishedEpisodeModified: true
			})
			queryClient.invalidateQueries(["episodes", selectedPodcast.id])
			queryClient.invalidateQueries(["episode", initialValues.id])
		}
	}

	const handleCreateRecording = recording => {
		return new Promise(async (resolve, reject) => {
			try {
				const {
					data: { recording_id: recordingId }
				} = await createRecording(recording)
				handleMarkAsDone("uploadAudio")
				setRecordingKey(recordingId)
				queryClient.invalidateQueries(["recording", initialValues.id])
				queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
				successAlert(SUCCESSFULLY_CREATED_RECORDING)
				resolve()
			} catch (e) {
				errorAlert(UNABLE_TO_CREATE_RECORDING)
				reject()
			}
		})
	}

	const handleRemoveRecording = async () => {
		try {
			setIsRemoved(true)
			resetRecordingFields()
			await updateRecording({
				...recording,
				episodeId: ""
			})
			handleMarkAsInProgress("uploadAudio")
			queryClient.invalidateQueries(["recording", initialValues.id])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
			successAlert(SUCCESSFULLY_REMOVED_RECORDING)
		} catch (e) {
			errorAlert(UNABLE_TO_REMOVE_RECORDING)
		}
	}

	const handleKeepBothRecordings = async (file, newFileName) => {
		try {
			setFilename(newFileName)
			setLoading(true)
			const duration = await extractDuration(file)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			const url = await uploadFile(uploadConfig, file, setProgress, null)
			setMedia(url)
			if (isEditDrawer) {
				if (isReplacingRecording) {
					await updateRecording({
						...recordingData,
						episodeId: ""
					})
				}
				await handleCreateRecording({
					...recording,
					fileName: newFileName,
					url: url,
					duration: duration,
					fileType: file.type,
					fileSize: file.size,
					episodeId: initialValues.id,
					podcastId: selectedPodcast.id
				})
				handleMarkAsDone("uploadAudio")
				setPublishedEpisodeModified()
			} else {
				setRecording({
					...recording,
					fileName: newFileName,
					url: url,
					duration: duration,
					fileType: file.type,
					fileSize: file.size,
					podcastId: selectedPodcast.id
				})
			}
			setLoading(false)
		} catch (e) {
			setLoading(false)
			errorAlert(UNABLE_TO_UPLOAD_RECORDING)
		}
	}

	const handleReplaceRecording = async (file, duplicateRecordingID) => {
		setFilename(file.name)
		setDuplicateRecordingID(duplicateRecordingID)
		try {
			const duration = await extractDuration(file)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			setLoading(true)
			const url = await uploadFile(uploadConfig, file, setProgress, null)
			setMedia(url)
			if (isEditDrawer) {
				await replaceRecording(duplicateRecordingID, {
					...recording,
					url: url,
					duration: duration,
					fileName: file.name,
					fileType: file.type,
					fileSize: file.size,
					episodeId: initialValues.id,
					podcastId: selectedPodcast.id
				})
				handleMarkAsDone("uploadAudio")
				queryClient.invalidateQueries(["recording", initialValues.id])
				queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
				setPublishedEpisodeModified()
				successAlert(SUCCESSFULLY_REPLACED_RECORDING)
			} else {
				setRecording({
					...recording,
					url: url,
					duration: duration,
					fileName: file.name,
					fileType: file.type,
					fileSize: file.size,
					podcastId: selectedPodcast.id
				})
			}
			setLoading(false)
		} catch (e) {
			setLoading(false)
			errorAlert(UNABLE_TO_REPLACE_RECORDING)
		}
	}
	const beforeUpload = async file => {
		return new Promise(async (resolve, reject) => {
			const { data } = await validateRecordingFileName(file.name, selectedPodcast.id)
			const isDuplicate = data.is_duplicate
			const isAssociatedWithEpisode = data.is_associated_with_episode
			const newFileName = data.new_file_name
			const duplicateID = data.duplicate_recording_id
			const associatedEpisodeID = data.associated_episode_id

			const recordingBelongsToSameEpisode = associatedEpisodeID === initialValues.id
			if (isDuplicate) {
				if (isAssociatedWithEpisode && !recordingBelongsToSameEpisode) {
					renameFileModal()
					reject()
				} else {
					duplicateFilenameModal(
						() => handleKeepBothRecordings(file, newFileName),
						() => handleReplaceRecording(file, duplicateID)
					)
					reject()
				}
			} else {
				const resp = await getSignedUrl(
					process.env.REACT_APP_RECORDINGS_BUCKET,
					`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
					file.type
				)
				setFilename(file.name)
				setUploadConfig({
					url: resp?.data?.url,
					bucket: resp?.data?.bucket,
					object: resp?.data?.object
				})
				setRecording({
					...recording,
					fileName: file.name,
					fileType: file.type,
					fileSize: file.size,
					podcastId: selectedPodcast.id
				})
				resolve()
			}
		})
	}

	// Chained with the beforeUpload() method of the main upload. Used for the Upload component of the "Replace" button.
	const beforeReplaceUpload = async file => {
		return new Promise((resolve, reject) => {
			setIsReplacingRecording(true)
			resolve()
		}).then(() => beforeUpload(file))
	}

	const uploadMedia = async ({ file }) => {
		setLoading(true)
		try {
			const duration = await extractDuration(file)
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			setMedia(url)
			if (isReplacingRecording) {
				await updateRecording({
					...recordingData,
					episodeId: ""
				})
				setPublishedEpisodeModified()
			}
			await handleCreateRecording({
				...recording,
				duration: duration,
				url: url,
				episodeId: initialValues.id
			})
			setRecording({
				...recording,
				duration: duration,
				url: url
			})
		} catch (e) {}
		setLoading(false)
	}

	const handleLoadedMedia = async event => {
		const duration = event?.target?.duration
		const time = formatTime(duration)
		setRecording(prev => ({ ...prev, duration }))
		setMediaDuration(time)
	}

	const releaseDateModified =
		!releaseDate?.isSame(moment(initialValues?.scheduledReleaseDate)) ||
		!releaseTime?.isSame(moment(initialValues?.scheduledReleaseDate))
	const isVideoPodcast = selectedPodcast?.mediaFormat === "video"
	const uploadMenu = (
		<Menu>
			<Menu.Item disabled style={{ cursor: "unset" }}>
				Add a file from...
			</Menu.Item>
			<Menu.Item>
				<Upload
					accept={isVideoPodcast ? ".mp4,.m4v,.mov" : ".mp3,.m4a"}
					showUploadList={false}
					listType="picture"
					beforeUpload={beforeUpload}
					customRequest={uploadMedia}
				>
					My Computer
				</Upload>
			</Menu.Item>
			<Menu.Item
				onClick={() => {
					if (isEditDrawer) {
						dispatch(setConfigForChooseRecordingModal({ episodeID: initialValues.id }))
					} else {
						dispatch(setConfigForChooseRecordingModal({ setRecording, setRecordingKey, setMedia }))
					}
					dispatch(toggleChooseRecordingModalVisible())
				}}
			>
				My Recordings
			</Menu.Item>
		</Menu>
	)

	const replaceUploadMenu = (
		<Menu>
			<Menu.Item disabled style={{ cursor: "unset" }}>
				Add a file from...
			</Menu.Item>
			<Menu.Item>
				<Upload
					accept={isVideoPodcast ? ".mp4,.m4v,.mov" : ".mp3,.m4a"}
					showUploadList={false}
					listType="picture"
					beforeUpload={beforeReplaceUpload}
					customRequest={uploadMedia}
				>
					My Computer
				</Upload>
			</Menu.Item>
			<Menu.Item
				onClick={() => {
					dispatch(setConfigForChooseRecordingModal({ isReplacing: true, episodeID: initialValues.id }))
					// dispatch(setConfigForChooseRecordingModal({ oldRecording: recordingData, episodeID: initialValues.id }))
					dispatch(toggleChooseRecordingModalVisible())
				}}
			>
				My Recordings
			</Menu.Item>
		</Menu>
	)

	const replaceButton = isEditDrawer ? (
		<PodpalButton size="large" variant="secondary">
			Replace
		</PodpalButton>
	) : (
		<PodpalButton size="large" variant="secondary" disabled>
			Replace
		</PodpalButton>
	)

	const footer = (
		<Row gutter={8} justify="end">
			<Col>
				<PodpalButton
					size="medium"
					variant="secondary"
					onClick={handleClose}
					disabled={!episodeModified && !releaseDateModified}
				>
					Cancel
				</PodpalButton>
			</Col>
			<Col>
				<PodpalButton
					size="medium"
					htmlType="submit"
					type="primary"
					loading={submitLoading}
					onClick={() => form.submit()}
					disabled={!episodeModified && !releaseDateModified}
				>
					{isEditDrawer ? "Save Episode" : "Add Episode"}
				</PodpalButton>
			</Col>
		</Row>
	)

	return (
		<Drawer
			className="new-episode-form-drawer"
			title="Episode Settings"
			width={isMobile ? "100%" : 550}
			maskClosable={false}
			keyboard={false}
			visible={visible}
			onClose={handleClose}
			destroyOnClose
			footer={footer}
		>
			{initialValues?.isPublished && !initialValues?.scheduledReleaseDate && initialValues?.publishedEpisodeModified && (
				<Row justify="center" gutter={[0, 24]}>
					<Col>
						<Alert type="error" banner message={EPISODE_ALREADY_PUBLISHED_ALERT} showIcon icon={<InfoCircleFilled />} />
					</Col>
				</Row>
			)}
			<Form
				form={form}
				onFinish={handleSubmit}
				onValuesChange={handleValuesChange}
				layout="vertical"
				initialValues={initialValues}
			>
				<Collapse defaultActiveKey={["1", "2", "3"]}>
					<Panel header="General" key="1">
						<p className="new-episode-form-panel-description">
							Basic information describing your episode including your episode artwork, type of episode (full, trailer, bonus),
							and episode description (or show notes). If you like, you can also assign a custom label for planning purposes.
						</p>
						<Form.Item
							name={FIELD_NAMES.PHOTO}
							label={LABEL_NAMES.PHOTO}
							tooltip={{
								placement: "right",
								title: (
									<span>
										This is the episode-specific artwork.
										<ul>
											<li>If uploading a custom image, it will override the podcast (show-level) artwork.</li>
											<li>
												Artwork must be a minimum size of 1400 x 1400 pixels and a maximum size of 3000 x 3000 pixels, in JPEG or
												PNG format, 72 dpi, with appropriate file extensions (.jpg, .png), and in the RGB colorspace.
											</li>
										</ul>
									</span>
								)
							}}
							rules={[
								{
									required: artworkType === ARTWORK_OPTIONS.CUSTOM,
									message: "Episode Artwork is required."
								},
								{
									validator: validateEpisodeArtwork
								}
							]}
						>
							<Input hidden value={artworkType === ARTWORK_OPTIONS.CUSTOM ? episodeArtworkImage : defaultPodcastArtwork} />
							<Row align="middle" gutter={[0, { xs: 16 }]}>
								<Col xs={24} sm={8} md={8} lg={8}>
									{artworkType === ARTWORK_OPTIONS.DEFAULT ? (
										<Avatar shape="square" size={128} src={defaultPodcastArtwork} />
									) : (
										<ImgCrop
											aspect={1}
											modalWidth={336}
											shape="rect"
											hasZoom={true}
											grid
											beforeCrop={checkPhotoFileType}
											modalOk="Apply"
											modalCancel="Cancel"
											modalTitle="Crop Image"
										>
											<ImageUpload beforeUpload={checkPhotoFileSize} imageUrl={episodeArtworkImage} />
										</ImgCrop>
									)}
								</Col>
								<Col xs={24} sm={8} md={8} lg={8}>
									<PodpalRadioButtonGroup
										optionType="default"
										value={artworkType}
										onChange={e => setArtworkType(e.target.value)}
										stacked
										buttons={episodeArtworkRadioButtons}
										size="large"
									/>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.TITLE}
							label={LABEL_NAMES.TITLE}
							tooltip={{
								placement: "right",
								title: (
									<span>
										<ul>
											<li>Do: Provide a clear, concise title that captures your audience's attention (125 characters max).</li>
											<li>Don’t: Include episode or season numbers in your title.</li>
											<li>Don’t: Repeat the title of your show within your episode title.</li>
										</ul>
									</span>
								)
							}}
							rules={[
								{
									required: true,
									message: TITLE_REQUIRED
								},
								{
									max: 125,
									message: TITLE_TOO_LONG
								}
							]}
						>
							<Input type="text" placeholder="Episode Title" size="large" />
						</Form.Item>

						<Row gutter={16}>
							<Col xs={12} md={6} lg={6}>
								<Form.Item
									name={FIELD_NAMES.SEASON_NUMBER}
									label={LABEL_NAMES.SEASON_NUMBER}
									tooltip={{
										placement: "right",
										title: (
											<span>
												<ul>
													<li>The episode season number. Where season is a non-zero integer (1, 2, 3, etc.).</li>
													<li>Apple Podcasts doesn’t display season numbers until you add a second season.</li>
												</ul>
											</span>
										)
									}}
									rules={[
										{
											required: !seasonsDisabled,
											message: SEASON_NUMBER_REQUIRED
										},
										{
											min: 1,
											message: INVALID_SEASON_NUMBER,
											type: "number"
										}
									]}
								>
									{seasonsDisabled ? (
										<Tooltip placement="bottom" title="Go to Show Settings to enable season numbers for this podcast.">
											<InputNumber name={FIELD_NAMES.SEASON_NUMBER} placeholder="Enter #" size="large" disabled={true} />
										</Tooltip>
									) : (
										<InputNumber name={FIELD_NAMES.SEASON_NUMBER} placeholder="Enter #" size="large" />
									)}
								</Form.Item>
							</Col>
							<Col xs={12} md={6} lg={6}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_NUMBER}
									label={LABEL_NAMES.EPISODE_NUMBER}
									tooltip={{
										placement: "right",
										title:
											"Enter an episode number in standard number format (1, 2, 3, etc.). Episode numbers are optional for episodic shows, but are mandatory for serial shows."
									}}
									rules={[
										{
											required: !episodesDisabled,
											message: EPISODE_NUMBER_REQUIRED
										},
										{
											min: 1,
											message: INVALID_EPISODE_NUMBER,
											type: "number"
										}
									]}
								>
									{episodesDisabled ? (
										<Tooltip placement="bottom" title="Go to Show Settings to enable episode numbers for this podcast.">
											<InputNumber name={FIELD_NAMES.EPISODE_NUMBER} placeholder="Enter #" size="large" disabled={true} />
										</Tooltip>
									) : (
										<InputNumber name={FIELD_NAMES.EPISODE_NUMBER} placeholder="Enter #" size="large" />
									)}
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.LABEL}
									label={LABEL_NAMES.LABEL}
									tooltip={{
										placement: "right",
										title:
											"Label or theme of the episode (20 characters max). We show this label on the episode card and other views within Podpal."
									}}
									rules={[
										{
											max: 1,
											message: TOO_MANY_LABELS,
											type: "array"
										},
										{
											validator: validateEpisodeLabel
										}
									]}
								>
									<Select placeholder="Enter label or theme" mode="tags" size="large" dropdownStyle={{ display: "none" }} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_TYPE}
									label={LABEL_NAMES.EPISODE_TYPE}
									tooltip={{
										placement: "right",
										title: (
											<span>
												The episode type:
												<ul>
													<li>
														<strong>Full</strong> (default). Specify full when you are submitting the <strong>complete</strong>{" "}
														content of the episode.
													</li>
													<li>
														<strong>Trailer</strong>. Specify trailer when you are submitting a short, promotional piece of content
														that represents a <strong>preview</strong> or <strong>teaser</strong> of your show.
													</li>
													<li>
														<strong>Bonus</strong>. Specify bonus when you are submitting <strong>extra content</strong> for your show
														(for example, behind the scenes information or interviews with the cast) or{" "}
														<strong>cross-promotional content</strong> for another show.
													</li>
												</ul>
											</span>
										)
									}}
									rules={[
										{
											required: true
										}
									]}
								>
									<PodpalRadioButtonGroup name={FIELD_NAMES.EPISODE_TYPE} buttons={episodeTypeRadioButtons} size="large" />
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.TRAILER_BONUS_TYPE}
									label={LABEL_NAMES.TRAILER_BONUS_TYPE}
									tooltip={{
										placement: "right",
										title: (
											<span>
												The trailer or bonus type:
												<ul>
													<li>
														<strong>Show Trailer/Bonus</strong>. No season or episode number.
													</li>
													<li>
														<strong>Season Trailer/Bonus</strong>. A season number and no episode number.
													</li>
													<li>
														<strong>Episode Trailer/Bonus</strong>. Episode number and optionally a season number.
													</li>
												</ul>
											</span>
										)
									}}
								>
									{trailerButtonsDisabled ? (
										<>
											<PodpalRadioButtonGroup
												name={FIELD_NAMES.TRAILER_BONUS_TYPE}
												buttons={trailerBonusTypeRadioButtons}
												size="large"
												disabled={true}
											/>
										</>
									) : (
										<PodpalRadioButtonGroup
											name={FIELD_NAMES.TRAILER_BONUS_TYPE}
											buttons={trailerBonusTypeRadioButtons}
											size="large"
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_PARENTAL_ADVISORY}
									label={LABEL_NAMES.EPISODE_PARENTAL_ADVISORY}
									tooltip={{
										placement: "right",
										title: (
											<span>
												The episode parental advisory information. Indicates the episode-specific explicit setting.
												<ul>
													<li>
														<strong>Clean</strong> - Indicates that the episode does not contain explicit language or adult content.
														Apple Podcasts displays a “Clean” parental advisory graphic for your episode.
													</li>
													<li>
														<strong>Explicit</strong> - Indicates the presence of explicit content. Apple Podcasts displays an
														“Explicit” parental advisory graphic for your episode.
													</li>
												</ul>
											</span>
										)
									}}
									rules={[
										{
											required: true
										}
									]}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.EPISODE_PARENTAL_ADVISORY}
										buttons={episodeParentalAdvisoryRadioButtons}
										size="large"
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.ITUNES_VISIBILITY}
									label={LABEL_NAMES.ITUNES_VISIBILITY}
									tooltip={{
										placement: "right",
										title:
											"The episode show or hide status in iTunes. Select “Hide” if you want this episode hidden in the Apple Podcast library of episodes for this podcast."
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.ITUNES_VISIBILITY}
										className={itunesVisibilityClassname}
										buttons={itunesVisibilityRadioButtons}
										size="large"
									/>
								</Form.Item>
							</Col>
						</Row>
					</Panel>
					<Panel header="Media Content" key="2">
						<p className="new-episode-form-panel-description">
							Upload your media file here by selecting a file from your recording library, or someplace on your computer.
						</p>
						<Row>
							<Col span={24}>
								<Form.Item
									label={LABEL_NAMES.RECORDING}
									tooltip={{
										placement: "right",
										title: (
											<span>
												Supported file types
												<ul>
													<li>
														<strong>Audio Podcast</strong>: M4A & MP3.
													</li>
													<li>
														<strong>Video Podcast</strong>: MOV, MP4, and M4V.
													</li>
												</ul>
											</span>
										)
									}}
								>
									<div style={{ display: media || loading ? "none" : undefined }}>
										<Dropdown overlay={uploadMenu} trigger={["click"]} className="episode-card-more">
											<PodpalButton size="large" type="primary">
												Upload
											</PodpalButton>
										</Dropdown>
									</div>
									{loading && <UploadRecordingCard filename={filename} progress={progress} ref={uploadCancelTokenRef} />}
									{media && !loading && (
										<>
											<Row gutter={[8, 16]} justify="center">
												<Col className="audio-bio-col">
													{isVideoPodcast ? (
														<video controls controlsList="nodownload" onLoadedMetadata={handleLoadedMedia} style={{ width: "100%" }}>
															<source src={media} type="video/mp4" />
														</video>
													) : (
														<audio controls controlsList="nodownload" onLoadedMetadata={handleLoadedMedia}>
															<source src={media} type="audio/mp3" />
															<source src={media} type="audio/mp4" />
														</audio>
													)}
												</Col>
											</Row>
											<Row justify="center">
												<Col>
													<StyledText>
														<strong>{recording?.fileName}</strong>
													</StyledText>
												</Col>
											</Row>
											<Row justify="center" gutter={[0, 16]}>
												<Col>
													<StyledText>{moment(recording?.createdAt).format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT)}</StyledText>
												</Col>
											</Row>
											<Row justify="center">
												<Col>
													<Space size={8}>
														<PodpalButton
															type="primary"
															size="large"
															onClick={() => handleDownload({ url: media, filename: recording?.fileName })}
														>
															Download
														</PodpalButton>
														{isEditDrawer && (
															<Dropdown overlay={replaceUploadMenu} trigger={["click"]} className="episode-card-more">
																{replaceButton}
															</Dropdown>
														)}
														{initialValues?.isPublished ? (
															<Tooltip
																placement="bottomRight"
																title="Once an episode has been published, you cannot remove the recording."
															>
																<PodpalButton variant="secondary" size="large" disabled>
																	Remove
																</PodpalButton>
															</Tooltip>
														) : (
															<PodpalButton variant="secondary" size="large" onClick={handleRemoveRecording} disabled={!media}>
																Remove
															</PodpalButton>
														)}
													</Space>
												</Col>
											</Row>
										</>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={8}>
								<Form.Item name={FIELD_NAMES.DURATION} hidden>
									<Input name={FIELD_NAMES.DURATION} />
								</Form.Item>
								{/* Display this formatted form field, but collect the unformatted data from the hidden field above. */}
								<label className="ant-form-item-label">
									{LABEL_NAMES.EPISODE_DURATION}
									<span className="form-tip">
										<Tooltip placement="right" title="The episode length/duration." mouseEnterDelay=".01">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								</label>
								<Input size="large" disabled value={media ? mediaDuration : "N/a"} />
							</Col>
						</Row>
					</Panel>
					<Panel header="Publishing, Hosting & Distribution" key="3">
						<p className="new-episode-form-panel-description">
							Set your target release date here to take advantage of Podpal planning features like status badges on episode
							cards and calendar notifications. Also, each time you publish to your RSS feed, we’ll update the information
							below for this episode.{" "}
						</p>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name={FIELD_NAMES.SCHEDULED_RELEASE_DATE}
									label={LABEL_NAMES.SCHEDULED_RELEASE_DATE}
									tooltip={{
										placement: "right",
										title: "The date and time you plan to publish this episode."
									}}
								>
									<Row gutter={8}>
										<Col span={12}>
											<DatePicker
												disabledDate={current => {
													return current && current < moment().subtract(1, "d") // Need to include current day in selectable options.
												}}
												style={{ width: "100%" }}
												size="large"
												value={releaseDate}
												format="dddd, M/D/YYYY"
												onChange={(date, dateString) => {
													setReleaseDate(date)
												}}
												disabled={initialValues?.isPublished}
											/>
										</Col>
										<Col span={12}>
											<TimePicker
												disabledHours={current => {
													if (moment().isSame(releaseDate, "day")) {
														return current && current < moment()
													}
												}}
												style={{ width: "100%" }}
												size="large"
												format="h:mm A"
												value={releaseTime}
												use12Hours
												onChange={(time, timeString) => {
													setReleaseTime(time)
												}}
												disabled={initialValues?.isPublished}
											/>
										</Col>
									</Row>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8} align="middle">
							<Col span={16}>
								<Form.Item
									label={LABEL_NAMES.LAST_PUBLISHED_DATE}
									tooltip={{
										placement: "right",
										title:
											"The date and time this episode was last published to the RSS Feed. Must be typed in the following format “DD/MM/YYYY at HH:MM PM EST”."
									}}
								>
									<Input value={lastPublishedDateFormatted} disabled size="large" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<PodpalButton
									variant="secondary"
									size="large"
									disabled={!episodePublished}
									onClick={() => {
										dispatch(toggleChangeLastPublishedDateModalVisible())
										dispatch(setEpisodeIdForLastPublishedDateModal(initialValues.id))
									}}
									style={{
										marginTop: "6px"
									}}
								>
									Modify Date
								</PodpalButton>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_URL}
									label={LABEL_NAMES.EPISODE_URL}
									tooltip={{
										placement: "right",
										title: "The hyperlink to this episode on your POD page."
									}}
								>
									<div className="button-field-container">
										<Input
											name={FIELD_NAMES.EPISODE_URL}
											value={episodeUrl}
											disabled
											size="large"
											onClick={() => {
												navigator.clipboard
													.writeText(episodeUrl)
													.then(res => {
														successAlert("Link copied to clipboard!")
													})
													.catch(err => {
														errorAlert("Failed to copy link to clipboard.")
													})
											}}
										/>
										<PodpalButton
											disabled={!episodePublished}
											type="primary"
											size="large"
											href={episodeUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											Visit
										</PodpalButton>
									</div>
								</Form.Item>
							</Col>
						</Row>
					</Panel>
				</Collapse>
			</Form>
		</Drawer>
	)
}

export default NewEpisodeFormDrawer
