import { MARK_ALL_NOTIFICATIONS_READ, DELETE_ALL_NOTIFICATIONS } from "constants/actionTypes"

const initialState = {
	unreadNotifications: new Set([1, 2, 3, 4, 5, 6, 7]),
	notifications: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
}

export default function (state = initialState, action) {
	switch (action.type) {
		case MARK_ALL_NOTIFICATIONS_READ:
			let temp = state.notifications
			for (let notification in state.unreadNotifications) {
				temp.push(notification)
			}
			return { notifications: temp, unreadNotifications: [] }

		case DELETE_ALL_NOTIFICATIONS:
			return { notifications: [], unreadNotifications: [] }

		default:
			return state
	}
}
