import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import styled from "styled-components"
import { Alert, Drawer, Divider, Form, Input, Select, Modal, Row, Col, Tooltip, Radio } from "antd"
import { useDispatch, useSelector } from "react-redux"
import useToggle from "hooks/useToggle"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import {
	FIELD_NAMES,
	LABEL_NAMES,
	MEDIA_FORMAT_OPTIONS,
	PARENTAL_ADVISORY_OPTIONS,
	SHOW_TYPE_OPTIONS,
	EPISODE_GROUPING_OPTIONS,
	EPISODE_NUMBERING_OPTIONS,
	TITLE_EMPTY_ERROR_MESSAGE,
	TITLE_TOO_LONG_ERROR_MESSAGE,
	SHOW_SUMMARY_EMPTY_ERROR_MESSAGE,
	SHOW_SUMMARY_TOO_LONG_ERROR_MESSAGE,
	PROFILE_URL_INVALID_ERROR_MESSAGE,
	CATEGORY_UNSELECTED_ERROR_MESSAGE,
	LANGUAGE_UNSELECTED_ERROR_MESSAGE,
	COUNTRY_UNSELECTED_ERROR_MESSAGE,
	PHOTO_TOO_LARGE_ERROR_MESSAGE,
	PARENTAL_ADVISORY_REQUIRED,
	MEDIA_FORMAT_REQUIRED,
	CATEGORY_TOO_MANY_SELECTED_ERROR_MESSAGE,
	AUTHORS_REQUIRED,
	TOO_MANY_AUTHORS_ERROR_MESSAGE,
	OWNER_NAME_REQUIRED,
	OWNER_NAME_TOO_SHORT_ERROR_MESSAGE,
	EMAIL_INVALID_ERROR_MESSAGE,
	COPYRIGHT_REQUIRED
} from "constants/strings/UserSettings/MyPodcasts/Form"
import { PODCAST_CATEGORIES, LANGUAGE_CODES, COUNTRY_CODES } from "utils/inputUtils"
import {
	toggleImportPodcastDrawer,
	setConfigForImportPodcastDrawer,
	toggle301RedirectModalVisible
} from "redux/actions/Podcasts/AddNewPodcast"
import { setPodcastIDForImportEpisodesModal } from "redux/actions/Episodes/AddNewEpisode"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalRadioButtonGroup from "components/common/Buttons/PodpalRadioGroup"
import BasicSelect from "components/common/Selects/BasicSelect"
import BasicTreeSelect from "components/common/Selects/BasicTreeSelect"
import ImgCrop from "antd-img-crop"
import ImageUpload from "components/common/Uploads/ImageUpload"
import RichTextEditor from "components/common/Input/RichTextEditor"
import useRichTextEditor from "hooks/useRichTextEditor"
import { validatePodcastProfileUrlService, migratePodcast } from "services/podcast.service"
import { useRSSParser } from "services/queries/Podcasts"
import { getBase64 } from "utils/b64"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { importFailedModal } from "components/common/Modals/Episodes/EpisodeManagementModals"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"

const podcastCategoriesData = []
Object.keys(PODCAST_CATEGORIES).forEach(parentCategory => {
	const children = []
	PODCAST_CATEGORIES[parentCategory].forEach(childCategory => {
		children.push({
			title: childCategory,
			value: childCategory,
			key: childCategory
		})
	})
	podcastCategoriesData.push({
		title: parentCategory,
		value: parentCategory,
		key: parentCategory,
		children: children
	})
})
const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 0;
	}

	label {
		display: flex;
		align-items: center;
		font: ${props => props.theme.fontSm} !important;
		font-weight: 500 !important;
		line-height: 12px !important;
		color: ${props => props.theme.textPrimary};
		margin-bottom: ${props => props.theme.spaceXs};
	}
`

const ImportPodcastDrawer = () => {
	const dispatch = useDispatch()

	const config = useSelector(state => state.addNewPodcast.importPodcastConfig)
	const visible = useSelector(state => state.addNewPodcast.importPodcastDrawerVisible)
	const { data, isSuccess, isError } = useRSSParser(config?.url)
	const [podcast, setPodcast] = useState(null)

	useEffect(() => {
		if (data?.podcast && isSuccess) {
			let language = data.podcast.language
			if (language === "en-us") {
				language = "en"
			}
			setPodcast({
				...data.podcast,
				[FIELD_NAMES.MEDIA_FORMAT]: config?.mediaFormat,
				[FIELD_NAMES.PARENTAL_ADVISORY]:
					data.podcast?.parentalAdvisory === "true" || data.podcast?.parentalAdvisory === "yes"
						? PARENTAL_ADVISORY_OPTIONS.EXPLICIT
						: PARENTAL_ADVISORY_OPTIONS.CLEAN,
				[FIELD_NAMES.CATEGORIES]: [],
				[FIELD_NAMES.LANGUAGE]: language,
				[FIELD_NAMES.EPISODE_GROUPING]: EPISODE_GROUPING_OPTIONS.NONE,
				[FIELD_NAMES.EPISODE_NUMBERING]: EPISODE_NUMBERING_OPTIONS.YES,
				[FIELD_NAMES.OLD_HOST]: config?.oldHost
			})
			if (!visible) {
				dispatch(toggleImportPodcastDrawer())
			}
		}
	}, [dispatch, data, isSuccess, visible, config])

	if (isError) {
		dispatch(setConfigForImportPodcastDrawer(null))
		importFailedModal()
		return null
	}

	return <ImportPodcastDrawerComponent initialValues={podcast} rssFeedURL={config?.url} />
}

const ImportPodcastDrawerComponent = ({ initialValues, rssFeedURL }) => {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()
	const size = useWindowSize()
	const isMobile = size.width < BREAKPOINT_XS

	const authUser = useSelector(state => state.app.authUser)
	const { data: userInfoData } = useUserInfo(authUser.uid)
	const isOnFreeOrStarterPlan =
		!userInfoData?.userInfo.subscriptionType ||
		userInfoData?.userInfo.subscriptionType === SubscriptionType.free ||
		userInfoData?.userInfo.subscriptionType === SubscriptionType.starter

	const visible = useSelector(state => state.addNewPodcast.importPodcastDrawerVisible)

	const [form] = Form.useForm()

	const handleClose = () => {
		dispatch(setConfigForImportPodcastDrawer(null))
		dispatch(toggleImportPodcastDrawer())
	}

	const [artworkBlob, setArtworkBlob] = useState(null)
	const [episodeNumberingDisabled, setEpisodeNumberingDisabled] = useToggle(false)
	const [submitLoading, toggleSubmitLoading] = useToggle(false)

	useEffect(() => {
		if (initialValues?.photoUrl) {
			setArtworkBlob(initialValues.photoUrl)
		}
	}, [initialValues])

	const maxShowSummaryLength = 4000
	const {
		content,
		handleChange: handleShowSummaryChange,
		setLength: setShowSummaryLength,
		contentLength
	} = useRichTextEditor(initialValues?.showSummary, maxShowSummaryLength)

	const podcastParentalAdvisoryRadioButtons = [
		{ label: "Clean", value: PARENTAL_ADVISORY_OPTIONS.CLEAN },
		{ label: "Explicit", value: PARENTAL_ADVISORY_OPTIONS.EXPLICIT }
	]

	const podcastShowTypeRadioButtons = [
		{ label: "Episodic", value: SHOW_TYPE_OPTIONS.EPISODIC },
		{ label: "Serial", value: SHOW_TYPE_OPTIONS.SERIAL }
	]
	const podcastEpisodeGroupingRadioButtons = [
		{ label: "None", value: EPISODE_GROUPING_OPTIONS.NONE },
		{ label: "Seasons", value: EPISODE_GROUPING_OPTIONS.SEASONS }
	]

	const podcastEpisodeNumberingRadioButtons = [
		{ label: "Yes", value: EPISODE_NUMBERING_OPTIONS.YES },
		{ label: "No", value: EPISODE_NUMBERING_OPTIONS.NO, disabled: episodeNumberingDisabled }
	]

	const checkPhotoFileSize = file => {
		getBase64(file, imageUrl => {
			setArtworkBlob(imageUrl)
		})

		return false
	}

	const checkPhotoFileType = (file, fileList) => {
		setArtworkBlob(file)
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
		if (!isJpgOrPng) {
			fileList[0]["response"] = "Invalid file type"
		}

		const isLt2M = file.size / 1024 / 1024 < 16
		if (!isLt2M) {
			Modal.error({
				title: "Sorry, but your file is too big!",
				content: PHOTO_TOO_LARGE_ERROR_MESSAGE,
				centered: true
			})
		}

		return isLt2M
	}

	const handlePhotoDimensionValidator = async () => {
		return Promise.resolve()
	}

	const handleValidatePodcastURL = async (rule, value) => {
		if (value?.length >= 3) {
			const res = await validatePodcastProfileUrlService(value)
			return res.data?.isValid !== undefined ? Promise.resolve() : Promise.reject("This url is already taken.")
		}
	}

	const handleValuesChange = (changedValues, allValues) => {
		const showType = changedValues[FIELD_NAMES.SHOW_TYPE]
		if (showType === SHOW_TYPE_OPTIONS.SERIAL) {
			setEpisodeNumberingDisabled(true)
			// setEpisodeNumbering(EPISODE_NUMBERING_OPTIONS.YES)
			form.setFields([
				{
					name: FIELD_NAMES.EPISODE_NUMBERING,
					value: "yes"
				}
			])
		} else if (showType === SHOW_TYPE_OPTIONS.EPISODIC) {
			setEpisodeNumberingDisabled(false)
		}
	}

	const handleSubmit = async values => {
		toggleSubmitLoading()
		try {
			values.showSummary = content
			values.categories = values.categories.map(category => category.value)
			const { data } = await migratePodcast(rssFeedURL, values, authUser.uid, initialValues.oldHost)
			dispatch(setPodcastIDForImportEpisodesModal(data.podcast_id))
			queryClient.invalidateQueries(["podcasts", authUser.uid])
			queryClient.invalidateQueries(["ownedPodcasts", authUser.uid])
			queryClient.invalidateQueries(["episodes", data.podcast_id])
			toggleSubmitLoading()
			handleClose()
			dispatch(toggle301RedirectModalVisible())
		} catch (e) {
			toggleSubmitLoading()
			errorAlert("Sorry, we were unable to migrate your podcast.")
		}
	}

	return (
		<Drawer
			className={`import-podcast-drawer`}
			title="Import an Existing Show"
			width={isMobile ? "100%" : 550}
			maskClosable={false}
			keyboard={false}
			visible={visible}
			onClose={handleClose}
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<PodpalButton size="medium" variant="secondary" onClick={handleClose}>
							Cancel
						</PodpalButton>
					</Col>
					<Col>
						<PodpalButton
							size="medium"
							loading={submitLoading}
							htmlType="submit"
							type="primary"
							onClick={() => form.submit()}
						>
							Import Podcast
						</PodpalButton>
					</Col>
				</Row>
			}
		>
			<Row>
				<Col>
					<Alert
						type="warning"
						banner
						showIcon
						message="We took a look at your current RSS Feed and imported the following information about your show. Please verify that the Show Settings below are correct, and click “Next” to import your episode data."
					/>
				</Col>
			</Row>
			<Divider />
			<StyledForm
				initialValues={initialValues}
				form={form}
				onFinish={handleSubmit}
				onValuesChange={handleValuesChange}
				layout="vertical"
			>
				<Row gutter={[0, 24]}>
					<Col>
						<Form.Item
							name={FIELD_NAMES.PHOTO}
							label={LABEL_NAMES.PHOTO}
							tooltip={{
								placement: "right",
								title: (
									<span>
										Podcast cover images must be square, minimum 1400 x 1400 and maximum 3000 x 3000 pixels, 72 dpi resolution,
										and RGB colorspace. Acceptable filetypes include JPEG and PNG (without a transparent background).{" "}
										<a
											href="https://podcasters.apple.com/support/896-artwork-requirements"
											target="_blank"
											rel="noopener noreferrer"
										>
											Learn more
										</a>{" "}
										about artwork requirements.
									</span>
								)
							}}
							rules={[
								{
									validator: handlePhotoDimensionValidator
								}
							]}
						>
							<Input hidden />
							<ImgCrop
								aspect={1}
								modalWidth={336}
								shape="rect"
								hasZoom={true}
								grid
								beforeCrop={checkPhotoFileType}
								modalOk="Apply"
								modalCancel="Cancel"
								modalTitle="Crop Image"
							>
								<ImageUpload beforeUpload={checkPhotoFileSize} imageUrl={artworkBlob} />
							</ImgCrop>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.TITLE}
							label={LABEL_NAMES.TITLE}
							rules={[
								{
									required: true,
									message: TITLE_EMPTY_ERROR_MESSAGE
								},
								{
									max: 50,
									message: TITLE_TOO_LONG_ERROR_MESSAGE
								}
							]}
							tooltip={{
								placement: "right",
								title: "It’s important to have a clear, concise name for your podcast. Be sure to make your title specific."
							}}
						>
							<Input name={FIELD_NAMES.TITLE} className="long-input" placeholder="Name of Podcast" size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.SHOW_SUMMARY}
							label={LABEL_NAMES.SHOW_SUMMARY}
							rules={[
								{
									required: true,
									validator: async (rule, value) => {
										if (!contentLength) {
											return Promise.reject(SHOW_SUMMARY_EMPTY_ERROR_MESSAGE)
										} else if (contentLength > 4000) {
											return Promise.reject(SHOW_SUMMARY_TOO_LONG_ERROR_MESSAGE)
										} else {
											return Promise.resolve()
										}
									}
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"Your show summary should contain one or more sentences describing your podcast to potential listeners. It cannot exceed 4000 characters."
							}}
						>
							<RichTextEditor
								placeholder="Tell us more about your show."
								setValue={handleShowSummaryChange}
								defaultValue={initialValues?.showSummary}
								setLength={setShowSummaryLength}
								height="200px"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.PROFILE_URL}
							label={LABEL_NAMES.PROFILE_URL}
							hasFeedback
							rules={[
								{
									required: true,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									min: 3,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									max: 50,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									pattern: /^[a-zA-Z0-9-]{1,}$/,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									transform: value => {
										if (value) {
											form.setFields([
												{
													name: FIELD_NAMES.PROFILE_URL,
													value: value.toLowerCase()
												}
											])
										}
									}
								},
								{
									validator: handleValidatePodcastURL
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"Your custom Podcast Profile URL links to your Podpal podcast page. It must contain 3-50 letters, numbers, or dashes. Please do not use spaces, symbols, or special characters."
							}}
						>
							<Input
								addonBefore="app.podpal.com/pod/"
								name={FIELD_NAMES.PROFILE_URL}
								placeholder="Claim your profile URL!"
								size="large"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 24]}>
					<Col xs={24} md={8} lg={8}>
						<Form.Item
							name={FIELD_NAMES.MEDIA_FORMAT}
							label={LABEL_NAMES.MEDIA_FORMAT}
							rules={[
								{
									required: true,
									message: MEDIA_FORMAT_REQUIRED
								}
							]}
							tooltip={{
								placement: "right",
								title: (
									<div>
										<div>
											Indicates the type of media format of your show. The chosen format type for this podcast cannot be changed
											after podcast creation.
										</div>
										<br />
										<div>
											<strong>Audio</strong>
										</div>
										<ul>
											<li>Supported by all podcast streaming channels.</li>
											<li>M4A & MP3 file format.</li>
										</ul>
										<div>
											<strong>Video</strong>
										</div>
										<ul>
											<li>Only supported by Apple Podcasts.</li>
											<li>MOV, MP4, and M4V file format.</li>
										</ul>
									</div>
								)
							}}
						>
							<PodpalRadioButtonGroup defaultValue={MEDIA_FORMAT_OPTIONS.AUDIO} name={FIELD_NAMES.MEDIA_FORMAT} size="large">
								<Radio.Button value={MEDIA_FORMAT_OPTIONS.AUDIO}>Audio</Radio.Button>
								<Tooltip
									placement="right"
									title={isOnFreeOrStarterPlan ? "Upgrade to a Podpal Pro or Agency Plan to import a video podcast." : ""}
								>
									<Radio.Button value={MEDIA_FORMAT_OPTIONS.VIDEO} disabled={isOnFreeOrStarterPlan}>
										Video
									</Radio.Button>
								</Tooltip>
							</PodpalRadioButtonGroup>
						</Form.Item>
					</Col>
					<Col xs={24} md={8} lg={8}>
						<Form.Item
							name={FIELD_NAMES.PARENTAL_ADVISORY}
							label={LABEL_NAMES.PARENTAL_ADVISORY}
							rules={[
								{
									required: true,
									message: PARENTAL_ADVISORY_REQUIRED
								}
							]}
							tooltip={{
								placement: "right",
								title: (
									<span>
										Indicates the default explicit setting of your show. Note that this can be changed on a per episode basis in
										episode settings.{" "}
										<ul>
											<li>
												<strong>Clean</strong> indicates that the episode does not contain explicit language or adult content. Apple
												Podcasts displays a “Clean” parental advisory graphic for your episode.
											</li>
											<li>
												<strong>Explicit</strong> indicates the presence of explicit content. Apple Podcasts displays an “Explicit”
												parental advisory graphic for your episode.
											</li>
										</ul>
									</span>
								)
							}}
						>
							<PodpalRadioButtonGroup buttons={podcastParentalAdvisoryRadioButtons} size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 24]}>
					<Col xs={24} md={12} lg={12}>
						<Form.Item
							name={FIELD_NAMES.COUNTRY}
							className="tree-select-form-item"
							label={LABEL_NAMES.COUNTRY}
							rules={[
								{
									required: true,
									message: COUNTRY_UNSELECTED_ERROR_MESSAGE
								}
							]}
							tooltip={{
								placement: "right",
								title: "The country where your podcast is being recorded/produced."
							}}
						>
							<BasicSelect placeholder="Please Select" options={COUNTRY_CODES} showSearch={true} size="large" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12} lg={12}>
						<Form.Item
							className="tree-select-form-item"
							name={FIELD_NAMES.LANGUAGE}
							label={LABEL_NAMES.LANGUAGE}
							rules={[
								{
									required: true,
									message: LANGUAGE_UNSELECTED_ERROR_MESSAGE
								}
							]}
							tooltip={{
								placement: "right",
								title: "The language spoken on your show."
							}}
						>
							<BasicSelect placeholder="Please Select" options={LANGUAGE_CODES} showSearch={true} size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 24]}>
					<Col xs={24} md={24} lg={24}>
						<Form.Item
							name={FIELD_NAMES.CATEGORIES}
							label={LABEL_NAMES.CATEGORIES}
							rules={[
								{
									required: true,
									message: CATEGORY_UNSELECTED_ERROR_MESSAGE,
									type: "array"
								},
								{
									max: 3,
									message: CATEGORY_TOO_MANY_SELECTED_ERROR_MESSAGE,
									type: "array"
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"Choose up to 3 categories that best reflect the content of your show. Note: Apple Podcasts will use the first category in this list to categorize your show. "
							}}
						>
							<BasicTreeSelect
								// onChange={(value) => setCategories(value)}
								// value={categories}
								treeDefaultExpandAll={true}
								treeData={podcastCategoriesData}
								placeholder="Please Select"
								size="large"
								maxTagCount={6}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 24]}>
					<Col xs={24} md={8} lg={8}>
						<Form.Item
							label={LABEL_NAMES.SHOW_TYPE}
							name={FIELD_NAMES.SHOW_TYPE}
							tooltip={{
								placement: "right",
								title: (
									<ul>
										<li>
											<strong>Episodic Podcasts</strong> (like sports and current events) order episodes from last to first, with
											the most recent recording at the top.
										</li>
										<li>
											<strong>Serial Podcasts</strong> (like storytelling, where the listening order is important) order the
											episodes from first to last similar to how you would experience reading a book.
										</li>
									</ul>
								)
							}}
						>
							<PodpalRadioButtonGroup buttons={podcastShowTypeRadioButtons} size="large"></PodpalRadioButtonGroup>
						</Form.Item>
					</Col>
					<Col xs={24} md={8} lg={8}>
						<Form.Item
							label={LABEL_NAMES.EPISODE_GROUPING}
							name={FIELD_NAMES.EPISODE_GROUPING}
							tooltip={{
								placement: "right",
								title: (
									<ul>
										<li>
											<strong>None</strong> indicates that you don’t plan on grouping episodes into seasons.
										</li>
										<li>
											<strong>Seasons</strong> says that you would like to break your episodes into seasons using season numbers.
										</li>
									</ul>
								)
							}}
						>
							<PodpalRadioButtonGroup buttons={podcastEpisodeGroupingRadioButtons} size="large"></PodpalRadioButtonGroup>
						</Form.Item>
					</Col>
					<Col xs={24} md={8} lg={8}>
						<Form.Item
							label={LABEL_NAMES.EPISODE_NUMBERING}
							name={FIELD_NAMES.EPISODE_NUMBERING}
							tooltip={{
								placement: "right",
								title: (
									<ul>
										<li>
											<strong>No</strong> indicates that you don’t want to associate a number with each episode.
										</li>
										<li>
											<strong>Yes</strong> says that you would like use episode numbers to catalog your show in podcast streaming
											channels like Apple Podcasts.
										</li>
									</ul>
								)
							}}
						>
							<PodpalRadioButtonGroup buttons={podcastEpisodeNumberingRadioButtons} size="large"></PodpalRadioButtonGroup>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 24]}>
					<Col span={12}>
						<Form.Item
							name={FIELD_NAMES.OWNER_NAME}
							label={LABEL_NAMES.OWNER_NAME}
							tooltip={{
								placement: "right",
								title:
									"The individual/group who owns the rights to the content of the show (the Owner). If needed, this info will be used to contact the owner of the podcast. It will not be publicly displayed."
							}}
							rules={[
								{
									required: true,
									message: OWNER_NAME_REQUIRED
								},
								{
									min: 2,
									message: OWNER_NAME_TOO_SHORT_ERROR_MESSAGE
								}
							]}
						>
							<Input name={FIELD_NAMES.OWNER_NAME} placeholder="Owner Name" size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={FIELD_NAMES.OWNER_EMAIL}
							label={LABEL_NAMES.OWNER_EMAIL}
							tooltip={{
								placement: "right",
								title:
									"The email of the individual/group who owns the content of the show (the Owner). If needed, this info will be used to contact the owner of the podcast. It will not be publicly displayed."
							}}
							rules={[
								{
									required: true,
									message: EMAIL_INVALID_ERROR_MESSAGE
								},
								{
									type: "email",
									message: EMAIL_INVALID_ERROR_MESSAGE
								}
							]}
						>
							<Input name={FIELD_NAMES.OWNER_EMAIL} placeholder="owner@domain.com" size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Form.Item
							className="tree-select-form-item"
							name={FIELD_NAMES.AUTHORS}
							label={LABEL_NAMES.AUTHORS}
							rules={[
								{
									required: true,
									message: AUTHORS_REQUIRED
								},
								{
									max: 10,
									message: TOO_MANY_AUTHORS_ERROR_MESSAGE,
									type: "array"
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"The individual or group/network responsible for creating the show (the Artist). Providing this information will allow listeners to see all shows created by the same entity."
							}}
						>
							<Select
								placeholder="Author Names"
								mode="tags"
								size="large"
								dropdownStyle={{ display: "none" }}
								tokenSeparators={[","]}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.COPYRIGHT}
							label={LABEL_NAMES.COPYRIGHT}
							tooltip="The show copyright details. The name of the individual or organization that owns the copyright to the show content."
							rules={[
								{
									required: true,
									message: COPYRIGHT_REQUIRED
								}
							]}
						>
							<Input name={FIELD_NAMES.COPYRIGHT} placeholder="e.g. John Doe, 2020" size="large" />
						</Form.Item>
					</Col>
				</Row>
			</StyledForm>
		</Drawer>
	)
}

export default ImportPodcastDrawer
