/**
 * GLOBAL APPLICATION STATE
 */
export const CHANGE_AUTH_USER = "CHANGE_AUTH_USER"
export const APP_LOADING = "APP_LOADING"
export const APP_LOCALE = "APP_LOCALE"

/**
 * User signup
 */
export const ADD_CHANGE_TO_GET_STARTED_FIELD = "ADD_CHANGE_TO_GET_STARTED_FIELD"
export const REVERT_CHANGES = "REVERT_CHANGES"
export const FIREBASE_USER_AUTH_CREATION_LOADING = "FIREBASE_USER_AUTH_CREATION_LOADING"
export const FIREBASE_USER_AUTH_CREATION_SUCCESS = "FIREBASE_USER_AUTH_CREATION_SUCCESS"
export const FIREBASE_USER_AUTH_CREATION_ERROR = "FIREBASE_USER_AUTH_CREATION_ERROR"
export const DATASTORE_USER_ENTITY_CREATION_LOADING = "DATASTORE_USER_ENTITY_CREATION_LOADING"
export const DATASTORE_USER_ENTITY_CREATION_SUCCESS = "DATASTORE_USER_ENTITY_CREATION_SUCCESS"
export const DATASTORE_USER_ENTITY_CREATION_ERROR = "DATASTORE_USER_ENTITY_CREATION_ERROR"
export const TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE = "TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE"
export const TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE = "TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE"

/**
 * User login
 */
export const ADD_CHANGE_TO_LOGIN_FIELD = "ADD_CHANGE_TO_LOGIN_FIELD"
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR"
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"
export const TOGGLE_FORGOT_PASSWORD_MODAL = "TOGGLE_FORGOT_PASSWORD_MODAL"
export const LOGIN_PAGE_LOADING = "LOGIN_PAGE_LOADING"
/**
 * Notifications Dropdown
 */
export const MARK_ALL_NOTIFICATIONS_READ = "MARK_ALL_NOTIFICATIONS_READ"
export const DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS"

export const PUSH_NEW_ITEM = "PUSH_NEW_ITEM"

export const REMOVE_ALL_ITEMS = "REMOVE_ALL_ITEMS"

/**
 * Help Center
 */
export const TOGGLE_HELP_CENTER_DROPDOWN = "TOGGLE_HELP_CENTER_DROPDOWN"
export const TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE = "TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE"
export const SET_VIDEO_TUTORIAL_MODAL_CONFIG = "SET_VIDEO_TUTORIAL_MODAL_CONFIG"
export const TOGGLE_WHATS_NEW_MODAL_VISIBLE = "TOGGLE_WHATS_NEW_MODAL_VISIBLE"

/**
 * User Settings Dropdown
 */
export const TOGGLE_USER_SETTINGS_DROPDOWN = "TOGGLE_USER_SETTINGS_DROPDOWN"

/**
 * Left sidebar actions
 */
export const TOGGLE_SIDEBAR_COLLAPSED = "TOGGLE_SIDEBAR_COLLAPSED"

/**
 * User Settings Sidebar
 */
export const TOGGLE_USER_SETTINGS_SIDEBAR_COLLAPSED = "TOGGLE_USER_SETTINGS_SIDEBAR_COLLAPSED"

/**
 * My Account Settings Page
 */
export const TOGGLE_NAVIGATION_ALLOWED_FLAG = "TOGGLE_NAVIGATION_ALLOWED_FLAG"
export const CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION = "CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION"

export const ADD_CHANGE_TO_FORM = "ADD_CHANGE_TO_FORM"
export const CHANGE_VALIDATION_STATUS = "CHANGE_VALIDATION_STATUS"
export const CLEAR_VALIDATION_STATUSES = "CLEAR_VALIDATION_STATUSES"
export const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES"
export const CHANGE_ERROR_MESSAGE = "CHANGE_ERROR_MESSAGE"

export const RETRIEVE_ACCOUNT_FORM_DATA_LOADING = "RETRIEVE_ACCOUNT_FORM_DATA_LOADING"
export const RETRIEVE_ACCOUNT_FORM_DATA_SUCCESS = "RETRIEVE_ACCOUNT_FORM_DATA_SUCCESS"
export const RETRIEVE_ACCOUNT_FORM_DATA_ERROR = "RETRIEVE_ACCOUNT_FORM_DATA_ERROR"

export const SUBMIT_ACCOUNT_FORM_DATA_LOADING = "SUBMIT_ACCOUNT_FORM_DATA_LOADING"
export const SUBMIT_ACCOUNT_FORM_DATA_SUCCESS = "SUBMIT_ACCOUNT_FORM_DATA_SUCCESS"
export const SUBMIT_ACCOUNT_FORM_DATA_ERROR = "SUBMIT_ACCOUNT_FORM_DATA_ERROR"

export const TOGGLE_NEW_EMAIL_MODAL_VISIBLE = "TOGGLE_NEW_EMAIL_MODAL_VISIBLE"

export const LINK_GOOGLE_ACCOUNT_LOADING = "LINK_GOOGLE_ACCOUNT_LOADING"
export const LINK_GOOGLE_ACCOUNT_SUCCESS = "LINK_GOOGLE_ACCOUNT_SUCCESS"
export const LINK_GOOGLE_ACCOUNT_ERROR = "LINK_GOOGLE_ACCOUNT_ERROR"
export const UNLINK_FACEBOOK_ACCOUNT_LOADING = "UNLINK_FACEBOOK_ACCOUNT_LOADING"
export const UNLINK_FACEBOOK_ACCOUNT_SUCCESS = "UNLINK_FACEBOOK_ACCOUNT_SUCCESS"
export const UNLINK_FACEBOOK_ACCOUNT_ERROR = "UNLINK_FACEBOOK_ACCOUNT_ERROR"
export const LINK_FACEBOOK_ACCOUNT_LOADING = "LINK_FACEBOOK_ACCOUNT_LOADING"
export const LINK_FACEBOOK_ACCOUNT_SUCCESS = "LINK_FACEBOOK_ACCOUNT_SUCCESS"
export const LINK_FACEBOOK_ACCOUNT_ERROR = "LINK_FACEBOOK_ACCOUNT_ERROR"
export const UNLINK_GOOGLE_ACCOUNT_LOADING = "UNLINK_GOOGLE_ACCOUNT_LOADING"
export const UNLINK_GOOGLE_ACCOUNT_SUCCESS = "UNLINK_GOOGLE_ACCOUNT_SUCCESS"
export const UNLINK_GOOGLE_ACCOUNT_ERROR = "UNLINK_GOOGLE_ACCOUNT_ERROR"
export const SET_GOOGLE_AUTHENTICATION_ON_LOAD = "SET_GOOGLE_AUTHENTICATION_ON_LOAD"
export const SET_FACEBOOK_AUTHENTICATION_ON_LOAD = "SET_FACEBOOK_AUTHENTICATION_ON_LOAD"

export const TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE = "TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE"

/**
 * My Podcasts
 */
export const SET_SELECTED_PODCAST = "SET_SELECTED_PODCAST"
export const TOGGLE_SHOW_SETTINGS_IS_MODIFIED = "TOGGLE_SHOW_SETTINGS_IS_MODIFIED"
export const TOGGLE_SHOW_SETTINGS_IS_LOADING = "TOGGLE_SHOW_SETTINGS_IS_LOADING"
export const SET_SOCIAL_MEDIA_FORM_IS_MODIFIED = "SET_SOCIAL_MEDIA_FORM_IS_MODIFIED"
export const SET_SOCIAL_MEDIA_FORM_IS_LOADING = "SET_SOCIAL_MEDIA_FORM_IS_LOADING"

export const ADD_CHANGE_TO_PODCAST_FORM = "ADD_CHANGE_TO_PODCAST_FORM"

export const RETRIEVE_PODCAST_DATA_LOADING = "RETRIEVE_PODCAST_DATA_LOADING"
export const RETRIEVE_PODCAST_DATA_SUCCESS = "RETRIEVE_PODCAST_DATA_SUCCESS"
export const RETRIEVE_PODCAST_DATA_ERROR = "RETRIEVE_PODCAST_DATA_ERROR"

export const SUBMIT_PODCAST_FORM_DATA_LOADING = "SUBMIT_PODCAST_FORM_DATA_LOADING"
export const SUBMIT_PODCAST_FORM_DATA_SUCCESS = "SUBMIT_PODCAST_FORM_DATA_SUCCESS"
export const SUBMIT_PODCAST_FORM_DATA_ERROR = "SUBMIT_PODCAST_FORM_DATA_ERROR"

export const VALIDATE_PODCAST_PROFILE_URL_LOADING = "VALIDATE_PODCAST_PROFILE_URL_LOADING"
export const VALIDATE_PODCAST_PROFILE_URL_SUCCESS = "VALIDATE_PODCAST_PROFILE_URL_SUCCESS"
export const VALIDATE_PODCAST_PROFILE_URL_ERROR = "VALIDATE_PODCAST_PROFILE_URL_ERROR"

export const TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE = "TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE"
export const TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE = "TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE"

/**
 * Subscription and Billing
 */
export const CHANGE_SUBSCRIPTION_AND_BILLING_SETTINGS_NAVBAR_SELECTED_KEY =
	"CHANGE_SUBSCRIPTION_AND_BILLING_SETTINGS_NAVBAR_SELECTED_KEY"
export const TOGGLE_LIMITS_EXCEEDED_MODAL_VISIBLE = "TOGGLE_LIMITS_EXCEEDED_MODAL_VISIBLE"

/**
 * TwoFAToggle
 */
export const OPEN_ENABLE_TWOFA_MODAL = "OPEN_ENABLE_TWOFA_MODAL"
export const CLOSE_ENABLE_TWOFA_MODAL = "CLOSE_ENABLE_TWOFA_MODAL"
export const CHANGE_ENABLE_TWOFA_MODAL_TITLE = "CHANGE_ENABLE_TWOFA_MODAL_TITLE"
export const CHANGE_ENABLE_TWOFA_MODAL_BODY = "CHANGE_ENABLE_TWOFA_MODAL_BODY"
export const CHANGE_ENABLE_TWOFA_MODAL_FOOTER = "CHANGE_ENABLE_TWOFA_MODAL_FOOTER"
export const CHANGE_ENABLE_TWOFA_MODAL_ON_OK_FUNCTION = "CHANGE_ENABLE_TWOFA_MODAL_ON_OK_FUNCTION"
export const TOGGLE_ENABLE_TWO_FA_PHONE_VERIFICATION_LOADING = "TOGGLE_ENABLE_TWO_FA_PHONE_VERIFICATION_LOADING"
export const SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_LOADING = "SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_LOADING"
export const SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_SUCCESS = "SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_SUCCESS"
export const SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_ERROR = "SEND_VERIFICATION_CODE_TO_PHONE_NUMBER_ERROR"
export const VERIFY_CODE_AND_LINK_PHONE_NUMBER_LOADING = "VERIFY_CODE_AND_LINK_PHONE_NUMBER_LOADING"
export const VERIFY_CODE_AND_LINK_PHONE_NUMBER_SUCCESS = "VERIFY_CODE_AND_LINK_PHONE_NUMBER_SUCCESS"
export const VERIFY_CODE_AND_LINK_PHONE_NUMBER_ERROR = "VERIFY_CODE_AND_LINK_PHONE_NUMBER_ERROR"
export const UPDATE_PHONE_NUMBER_LOADING = "UPDATE_PHONE_NUMBER_LOADING"
export const UPDATE_PHONE_NUMBER_SUCCESS = "UPDATE_PHONE_NUMBER_SUCCESS"
export const UPDATE_PHONE_NUMBER_ERROR = "UPDATE_PHONE_NUMBER_ERROR"
export const UNLINK_PHONE_NUMBER_LOADING = "UNLINK_PHONE_NUMBER_LOADING"
export const UNLINK_PHONE_NUMBER_SUCCESS = "UNLINK_PHONE_NUMBER_SUCCESS"
export const UNLINK_PHONE_NUMBER_ERROR = "UNLINK_PHONE_NUMBER_ERROR"
export const VERIFY_PHONE_CREDENTIALS_LOADING = "VERIFY_PHONE_CREDENTIALS_LOADING"
export const VERIFY_PHONE_CREDENTIALS_SUCCESS = "VERIFY_PHONE_CREDENTIALS_SUCCESS"
export const VERIFY_PHONE_CREDENTIALS_ERROR = "VERIFY_PHONE_CREDENTIALS_ERROR"

export const OPEN_CHANGE_TWOFA_MODAL = "OPEN_CHANGE_TWOFA_MODAL"
export const CLOSE_CHANGE_TWOFA_MODAL = "CLOSE_CHANGE_TWOFA_MODAL"
export const CHANGE_CHANGE_TWOFA_MODAL_TITLE = "CHANGE_CHANGE_TWOFA_MODAL_TITLE"
export const TOGGLE_CHANGE_TWO_FA_PHONE_VERIFICATION_LOADING = "TOGGLE_CHANGE_TWO_FA_PHONE_VERIFICATION_LOADING"

export const OPEN_REMOVE_TWOFA_MODAL = "OPEN_REMOVE_TWOFA_MODAL"
export const CLOSE_REMOVE_TWOFA_MODAL = "CLOSE_REMOVE_TWOFA_MODAL"
export const CHANGE_REMOVE_TWOFA_MODAL_TITLE = "CHANGE_REMOVE_TWOFA_MODAL_TITLE"
export const TOGGLE_REMOVE_TWO_FA_PHONE_VERIFICATION_LOADING = "TOGGLE_REMOVE_TWO_FA_PHONE_VERIFICATION_LOADING"

/**
 * Podcasts
 */
export const TOGGLE_ADD_NEW_PODCAST_DRAWER = "TOGGLE_ADD_NEW_PODCAST_DRAWER"
export const TOGGLE_IMPORT_PODCAST_DRAWER = "TOGGLE_IMPORT_PODCAST_DRAWER"
export const SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER = "SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER"
export const SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER = "SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER"
export const TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE = "TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE"
export const TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE = "TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE"
export const ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM = "ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM"
export const CHANGE_ADD_NEW_PODCAST_FORM_VALIDATION_STATUS = "CHANGE_ADD_NEW_PODCAST_FORM_VALIDATION_STATUS"
export const CHANGE_ADD_NEW_PODCAST_FORM_ERROR_MESSAGE = "CHANGE_ADD_NEW_PODCAST_FORM_ERROR_MESSAGE"
export const CLEAR_ADD_NEW_PODCAST_FORM_VALIDATION_STATUSES = "CLEAR_ADD_NEW_PODCAST_FORM_VALIDATION_STATUSES"
export const CLEAR_ADD_NEW_PODCAST_FORM_ERROR_MESSAGES = "CLEAR_ADD_NEW_PODCAST_FORM_ERROR_MESSAGES"

export const SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL = "SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL"

export const CREATE_NEW_PODCAST_LOADING = "CREATE_NEW_PODCAST_LOADING"
export const CREATE_NEW_PODCAST_SUCCESS = "CREATE_NEW_PODCAST_SUCCESS"
export const CREATE_NEW_PODCAST_ERROR = "CREATE_NEW_PODCAST_ERROR"

export const TOGGLE_DELETE_PODCAST_MODAL_VISIBLE = "TOGGLE_DELETE_PODCAST_MODAL_VISIBLE"

export const DELETE_PODCAST_LOADING = "DELETE_PODCAST_LOADING"
export const DELETE_PODCAST_SUCCESS = "DELETE_PODCAST_SUCCESS"
export const DELETE_PODCAST_ERROR = "DELETE_PODCAST_ERROR"

export const REDIRECT_PODCAST_LOADING = "REDIRECT_PODCAST_LOADING"
export const REDIRECT_PODCAST_SUCCESS = "REDIRECT_PODCAST_SUCCESS"
export const REDIRECT_PODCAST_ERROR = "REDIRECT_PODCAST_ERROR"

export const CREATE_NEW_PODCAST_ROLE_LOADING = "CREATE_NEW_PODCAST_ROLE_LOADING"
export const CREATE_NEW_PODCAST_ROLE_SUCCESS = "CREATE_NEW_PODCAST_ROLE_SUCCESS"
export const CREATE_NEW_PODCAST_ROLE_ERROR = "CREATE_NEW_PODCAST_ROLE_ERROR"

export const RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING = "RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING"
export const RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS = "RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS"
export const RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR = "RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR"

export const RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_LOADING = "RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_LOADING"
export const RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_SUCCESS = "RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_SUCCESS"
export const RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_ERROR = "RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_ERROR"

export const TOGGLE_PODCAST_SETTINGS_LOADING = "TOGGLE_PODCAST_SETTINGS_LOADING"

export const TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE = "TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE"

export const SET_PODCAST_ID_FOR_301_REDIRECT_MODAL = "SET_PODCAST_ID_FOR_301_REDIRECT_MODAL"
export const TOGGLE_301_REDIRECT_MODAL_VISIBLE = "TOGGLE_301_REDIRECT_MODAL_VISIBLE"
export const TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE = "TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE"

/**
 * Episodes
 */
export const TOGGLE_ADD_NEW_EPISODE_DRAWER = "TOGGLE_ADD_NEW_EPISODE_DRAWER"
export const TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE = "TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE"

export const CREATE_NEW_EPISODE_LOADING = "CREATE_NEW_EPISODE_LOADING"
export const CREATE_NEW_EPISODE_SUCCESS = "CREATE_NEW_EPISODE_SUCCESS"
export const CREATE_NEW_EPISODE_ERROR = "CREATE_NEW_EPISODE_ERROR"

export const SET_EPISODE_ID_FOR_CONFIRMATION_MODAL = "SET_EPISODE_ID_FOR_CONFIRMATION_MODAL"
export const SET_EPISODE_ID_FOR_INITIAL_VALUES = "SET_EPISODE_ID_FOR_INITIAL_VALUES"
export const SET_EPISODE_LAST_PUBLISHED_DATE = "SET_EPISODE_LAST_PUBLISHED_DATE"

export const TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE = "TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE"
export const TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE = "TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE"
export const TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE = "TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE"
export const TOGGLE_DUPLICATE_FILENAME_MODAL_VISIBLE = "TOGGLE_DUPLICATE_FILENAME_MODAL_VISIBLE"

export const SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL = "SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL"
export const SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL = "SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL"
export const SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL = "SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL"

export const TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE = "TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE"
export const SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL = "SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL"

export const TOGGLE_DELETE_EPISODE_MODAL_VISIBLE = "TOGGLE_DELETE_EPISODE_MODAL_VISIBLE"
export const SET_EPISODE_FOR_DELETE_EPISODE_MODAL = "SET_EPISODE_FOR_DELETE_EPISODE_MODAL"

/**
 * Recordings
 */
export const TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE = "TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE"
export const SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL = "SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL"
export const TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE = "TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE"
export const SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL = "SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL"
export const SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL = "SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL"
export const TOGGLE_DELETE_RECORDING_MODAL_VISIBLE = "TOGGLE_DELETE_RECORDING_MODAL_VISIBLE"
export const SET_CONFIG_FOR_DELETE_RECORDING_MODAL = "SET_CONFIG_FOR_DELETE_RECORDING_MODAL"

/**
 * Team Management
 */
export const TOGGLE_INVITE_TEAM_MODAL = "TOGGLE_INVITE_TEAM_MODAL"
export const TOGGLE_MANAGE_ROLES_MODAL = "TOGGLE_MANAGE_ROLES_MODAL"
