import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
import { useQueryClient } from "react-query"
import { Collapse, Form, Input, Modal, Row, Col, Select, Tooltip } from "antd"
import ColorPicker from "components/common/Input/ColorPicker"
import useColorPicker from "components/common/Input/ColorPicker/useColorPicker"
import useToggle from "hooks/useToggle"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import {
	LABEL_NAMES,
	FIELD_NAMES,
	MEDIA_FORMAT_OPTIONS,
	PARENTAL_ADVISORY_OPTIONS,
	UPDATE_STATUS_OPTIONS,
	ITUNES_VISIBILITY_OPTIONS,
	SHOW_TYPE_OPTIONS,
	PHOTO_TOO_LARGE_ERROR_MESSAGE,
	TITLE_TOO_LONG_ERROR_MESSAGE,
	SUBTITLE_TOO_LONG_ERROR_MESSAGE,
	SHOW_SUMMARY_TOO_LONG_ERROR_MESSAGE,
	SHOW_SUMMARY_EMPTY_ERROR_MESSAGE,
	PROFILE_URL_INVALID_ERROR_MESSAGE,
	PROFILE_URL_EMPTY_ERROR_MESSAGE,
	CATEGORY_UNSELECTED_ERROR_MESSAGE,
	CATEGORY_TOO_MANY_SELECTED_ERROR_MESSAGE,
	LANGUAGE_UNSELECTED_ERROR_MESSAGE,
	KEYWORDS_TOO_MANY_ADDED_ERROR_MESSAGE,
	EXTERNAL_WEBSITE_INVALID_URL_ERROR_MESSAGE,
	EMAIL_INVALID_ERROR_MESSAGE,
	TOO_MANY_AUTHORS_ERROR_MESSAGE,
	OWNER_NAME_TOO_SHORT_ERROR_MESSAGE,
	TITLE_EMPTY_ERROR_MESSAGE,
	PARENTAL_ADVISORY_REQUIRED,
	MEDIA_FORMAT_REQUIRED,
	AUTHORS_REQUIRED,
	OWNER_NAME_REQUIRED,
	COUNTRY_UNSELECTED_ERROR_MESSAGE,
	COPYRIGHT_REQUIRED
} from "constants/strings/UserSettings/MyPodcasts/Form"
import { PODCAST_CATEGORIES, LANGUAGE_CODES, COUNTRY_CODES } from "utils/inputUtils"
import PodpalRadioButtonGroup from "components/common/Buttons/PodpalRadioGroup"
import ImageUpload from "components/common/Uploads/ImageUpload"
import ImgCrop from "antd-img-crop"
import RichTextEditor from "components/common/Input/RichTextEditor"
import useRichTextEditor from "hooks/useRichTextEditor"
import BasicSelect from "components/common/Selects/BasicSelect"
import BasicTreeSelect from "components/common/Selects/BasicTreeSelect"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import colors from "styles/scss/color.scss"
import { useDispatch, useSelector } from "react-redux"
import { toggleDeletePodcastModalVisible } from "redux/actions/Podcasts"
import { updatePodcast, validatePodcastProfileUrlService } from "services/podcast.service"
import moment from "moment"
import "./ShowSettingsForm.scss"
import { Prompt } from "react-router"
import { getBase64 } from "utils/b64"
import { usePodcast } from "services/queries/Podcasts"
import AudioUpload from "components/common/Uploads/AudioUpload"
import useAudioUplaod from "components/common/Uploads/AudioUpload/useAudioUpload"
import { POD } from "constants/routes"
import { Link } from "react-router-dom"
import { usePodcastRole } from "services/queries/PodcastRole"

const { Panel } = Collapse
const { TextArea } = Input

const podcastCategoriesData = []
Object.keys(PODCAST_CATEGORIES).forEach(parentCategory => {
	const children = []
	PODCAST_CATEGORIES[parentCategory].forEach(childCategory => {
		children.push({
			title: childCategory,
			value: childCategory,
			key: childCategory
		})
	})
	podcastCategoriesData.push({
		title: parentCategory,
		value: parentCategory,
		key: parentCategory,
		children: children
	})
})

export const ShowSettingsForm = () => {
	const dispatch = useDispatch()

	const [form] = Form.useForm()
	const [newPhotoFile, setNewPhotoFile] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	// const [autoSchedule, setAutoSchedule] = useState(null)
	const [isModified, setIsModified] = useState(false)
	const [initialColor, setInitialColor] = useState(false)
	const queryClient = useQueryClient()

	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const podcastId = selectedPodcast?.id

	const { isLoading: isPodcastLoading, data } = usePodcast(podcastId)
	const { data: podcastRoleData } = usePodcastRole({ podcastId: selectedPodcast?.id, userId: authUser.uid })

	const size = useWindowSize()
	const isMobile = size.width < BREAKPOINT_XS

	const [artworkBlob, setArtworkBlob] = useState(null)

	const [itunesVisibilityClassname, setItunesVisibilityClassname] = useState("")

	const addChange = (key, value) => {
		form.setFieldsValue({ [key]: value })
		if (!_.isEqual(value, data?.podcast[key])) setIsModified(true)
	}

	const maxShowSummaryLength = 4000

	const {
		content,
		handleChange: handleShowSummaryChange,
		setLength: setShowSummaryLength,
		contentLength
	} = useRichTextEditor(data?.podcast?.showSummary, maxShowSummaryLength)

	const [showSummaryModified, setShowSummaryModified] = useState(false)
	useEffect(() => {
		if (data?.podcast && content && content !== data.podcast?.showSummary) {
			setShowSummaryModified(true)
		}
	}, [content, data])

	const {
		setDefaultColor,
		showColorPicker,
		selectedColor,
		setSelectedColor,
		handleColorPickerClose,
		handleColorChange,
		handleColorPickerClick
	} = useColorPicker({
		onColorChange: value => {
			addChange(FIELD_NAMES.COLOR_PICKER, value)
		}
	})

	const {
		webAudio,
		setWebAudio,
		localAudio,
		setLocalAudio,
		audioErrorMessage,
		audioBioRemoved,
		validateAudioFile,
		handleRemoveLocalAudio
	} = useAudioUplaod({
		maxDurationSeconds: 60,
		onLoad: result => {
			addChange(FIELD_NAMES.AUDIO_BIO, result)
		},
		onRemove: () => {
			addChange(FIELD_NAMES.AUDIO_BIO, null)
			addChange("audioBioUrl", "")
		}
	})

	useEffect(() => {
		if (data?.podcast?.color && !initialColor) {
			setDefaultColor(data.podcast.color)
			setInitialColor(true)
		}
	}, [data, setDefaultColor, initialColor])

	useEffect(() => {
		if (data?.podcast) {
			setItunesVisibilityClassname(
				data?.podcast[FIELD_NAMES.ITUNES_VISIBILITY] === ITUNES_VISIBILITY_OPTIONS.HIDE
					? "itunes-visibility-radio-button"
					: ""
			)
			if (data.podcast.audioBioUrl && !audioBioRemoved) {
				setWebAudio(`${data.podcast.audioBioUrl}?noCache=${Math.floor(Math.random() * 1000000)}`)
			}
		}
	}, [data?.podcast]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (newPhotoFile) setIsModified(true)
	}, [newPhotoFile])

	const podcastMediaRadioButtons = [
		{ label: "Audio", value: MEDIA_FORMAT_OPTIONS.AUDIO },
		{ label: "Video", value: MEDIA_FORMAT_OPTIONS.VIDEO }
	]
	const podcastParentalAdvisoryRadioButtons = [
		{ label: "Clean", value: PARENTAL_ADVISORY_OPTIONS.CLEAN },
		{ label: "Explicit", value: PARENTAL_ADVISORY_OPTIONS.EXPLICIT }
	]

	const podcastUpdateStatusRadioButtons = [
		{ label: "Active", value: UPDATE_STATUS_OPTIONS.ACTIVE },
		{ label: "Complete", value: UPDATE_STATUS_OPTIONS.COMPLETE }
	]

	const podcastItunesVisiblityRadioButtons = [
		{ label: "Show", value: ITUNES_VISIBILITY_OPTIONS.SHOW },
		{ label: "Hide", value: ITUNES_VISIBILITY_OPTIONS.HIDE }
	]

	const podcastShowTypeRadioButtons = [
		{ label: "Episodic", value: SHOW_TYPE_OPTIONS.EPISODIC },
		{ label: "Serial", value: SHOW_TYPE_OPTIONS.SERIAL }
	]
	const podcastEpisodeGroupingRadioButtons = [
		{ label: "None", value: "none" },
		{ label: "Seasons", value: "seasons" }
	]

	const [episodeNumberingDisabled, setEpisodeNumberingDisabled] = useToggle(
		data?.podcast?.[FIELD_NAMES.SHOW_TYPE] === SHOW_TYPE_OPTIONS.SERIAL
	)

	const podcastEpisodeNumberingRadioButtons = [
		{ label: "Yes", value: "yes" },
		{ label: "No", value: "no", disabled: episodeNumberingDisabled }
	]

	// const podcastDefaultReleaseCadenceRadioButtons = [
	// 	{ label: "Daily", value: "daily" },
	// 	{ label: "Weekly", value: "weekly" },
	// 	{ label: "Bi-Weekly", value: "biweekly" },
	// 	{ label: "Monthly", value: "monthly" },
	// ]

	// const podcastDefaultReleaseDayRadioButtons = [
	// 	{ label: "Mon", value: "mon" },
	// 	{ label: "Tue", value: "tue" },
	// 	{ label: "Wed", value: "wed" },
	// 	{ label: "Th", value: "th" },
	// 	{ label: "Fri", value: "fri" },
	// 	{ label: "Sat", value: "sat" },
	// 	{ label: "Sun", value: "sun" },
	// ]

	const handleOnFinishFailed = () => {
		errorAlert("Please fix errors to save!")
	}

	const handleSubmit = async values => {
		if (newPhotoFile) {
			values.newPhotoFile = newPhotoFile
		}
		values.isComplete = values[FIELD_NAMES.UPDATE_STATUS] === UPDATE_STATUS_OPTIONS.COMPLETE ? true : false
		values.showSummary = content ?? data.podcast.showSummary
		setIsLoading(true)
		setShowSummaryModified(false)
		try {
			await updatePodcast({ podcastId, data: { ...data?.podcast, ...values }, userId: authUser.uid })
			successAlert("Changes saved!")
			setIsModified(false)
			queryClient.invalidateQueries(["podcast", podcastId])
		} catch (error) {
			errorAlert("Error occurred while saving.")
		}
		setIsLoading(false)
	}

	const handleCancel = useCallback(() => {
		setNewPhotoFile(null)
		// setAutoSchedule(null)
		setLocalAudio(null)
		setItunesVisibilityClassname(
			data?.[FIELD_NAMES.ITUNES_VISIBILITY] === ITUNES_VISIBILITY_OPTIONS.HIDE ? "itunes-visibility-radio-button" : ""
		)
		form.resetFields()
		setSelectedColor(data?.podcast[FIELD_NAMES.COLOR_PICKER] || colors.podpalBlue)
		setIsModified(false)
		setShowSummaryModified(false)
	}, [form, data, setSelectedColor, setLocalAudio])

	const handleMultipleTreeSelectChange = value => {
		const values = value.map(v => v.value)
		addChange(FIELD_NAMES.CATEGORIES, values)
	}

	const checkPhotoFileSize = file => {
		getBase64(file, imageUrl => {
			setNewPhotoFile(imageUrl)
		})

		return false
	}

	const checkPhotoFileType = (file, fileList) => {
		setArtworkBlob(file)
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
		if (!isJpgOrPng) {
			fileList[0]["response"] = "Invalid file type"
		}

		const isLt2M = file.size / 1024 / 1024 < 16
		if (!isLt2M) {
			Modal.error({
				title: "Sorry, but your file is too big!",
				content: PHOTO_TOO_LARGE_ERROR_MESSAGE,
				centered: true
			})
		}

		return isLt2M
	}

	const readFileAsync = async file => {
		return new Promise(resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = e => {
				const image = new Image()
				image.src = e.target.result
				resolve([image.height, image.width])
			}
		})
	}

	const handlePhotoDimensionValidator = async () => {
		if (artworkBlob !== null) {
			const [height, width] = await readFileAsync(artworkBlob)
			if (height > 3000 && width > 3000) {
				return Promise.reject("Podcast artwork exceeds the maximum dimension limit.")
			} else if (height < 1400 && width < 1400) {
				return Promise.reject("Podcast artwork does not meet the minimum dimension limit.")
			} else if (height > 3000 || width > 3000 || height < 1400 || width < 1400) {
				return Promise.reject("Podcast artwork does not meet dimension limits")
			}
		}
		return Promise.resolve()
	}

	const handleValidatePodcastURL = async (rule, value, callback) => {
		const initialValue = data?.podcast?.[FIELD_NAMES.PROFILE_URL]

		if (value?.length >= 3) {
			const res = await validatePodcastProfileUrlService(value)
			return res.data.isValid !== undefined || value === initialValue
				? callback()
				: Promise.reject("This url is already taken.")
		} else {
			return
		}
	}

	const checkIfModified = (changedValues, allValues) => {
		const showType = changedValues[FIELD_NAMES.SHOW_TYPE]
		if (showType === SHOW_TYPE_OPTIONS.SERIAL) {
			setEpisodeNumberingDisabled(true)
			addChange(FIELD_NAMES.EPISODE_NUMBERING, "yes")
		} else if (showType === SHOW_TYPE_OPTIONS.EPISODIC) {
			setEpisodeNumberingDisabled(false)
		}

		if (changedValues[FIELD_NAMES.OWNER_NAME] || changedValues[FIELD_NAMES.OWNER_EMAIL]) {
			form.validateFields([FIELD_NAMES.OWNER_NAME, FIELD_NAMES.OWNER_EMAIL])
		}

		const newItunesVisibility = changedValues[FIELD_NAMES.ITUNES_VISIBILITY]
		if (newItunesVisibility) {
			setItunesVisibilityClassname(
				newItunesVisibility === ITUNES_VISIBILITY_OPTIONS.HIDE ? "itunes-visibility-radio-button" : ""
			)
		}

		let mod = false
		for (const [key, value] of Object.entries(changedValues)) {
			if (!_.isEqual(value, data?.podcast[key])) mod = true
		}
		setIsModified(mod)
	}

	const EMPTY_ARTWORK = `https://storage.googleapis.com/${process.env.REACT_APP_PODCAST_PHOTOS_BUCKET}/1/1`
	let podcastAlbumCoverImage = newPhotoFile
		? newPhotoFile
		: data?.podcast?.[FIELD_NAMES.PHOTO]?.length > 0
		? data?.podcast?.[FIELD_NAMES.PHOTO]
		: EMPTY_ARTWORK

	if (isPodcastLoading || !data) return null
	// const showAutoPublishFields = autoSchedule ?? data.podcast?.[FIELD_NAMES.AUTO_SCHEDULE]
	return (
		<div className="show-settings-form">
			<Form
				scrollToFirstError
				form={form}
				onFinish={handleSubmit}
				onFinishFailed={handleOnFinishFailed}
				layout="vertical"
				initialValues={{
					...data.podcast,
					[FIELD_NAMES.DEFAULT_RELEASE_TIME]: data.podcast[FIELD_NAMES.DEFAULT_RELEASE_TIME]
						? moment(data.podcast[FIELD_NAMES.DEFAULT_RELEASE_TIME])
						: moment().startOf("day")
				}}
				onValuesChange={checkIfModified}
			>
				<Form.Item
					name={FIELD_NAMES.PHOTO}
					label={LABEL_NAMES.PHOTO}
					tooltip={{
						placement: "right",
						title: (
							<span>
								Podcast cover images must be square, minimum 1400 x 1400 and maximum 3000 x 3000 pixels, 72 dpi resolution, and
								RGB colorspace. Acceptable filetypes include JPEG and PNG.{" "}
								<a
									href="https://podcasters.apple.com/support/896-artwork-requirements"
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									Learn more
								</a>{" "}
								about artwork requirements.
							</span>
						)
					}}
					rules={[
						{
							validator: handlePhotoDimensionValidator
						}
					]}
				>
					<Input hidden value={podcastAlbumCoverImage} />
					<ImgCrop
						aspect={1}
						modalWidth={336}
						shape="rect"
						hasZoom={true}
						grid
						beforeCrop={checkPhotoFileType}
						modalOk="Apply"
						modalCancel="Cancel"
						modalTitle="Crop Image"
					>
						<ImageUpload
							className="show-settings-podcast-artwork"
							beforeUpload={checkPhotoFileSize}
							imageUrl={podcastAlbumCoverImage}
						/>
					</ImgCrop>
				</Form.Item>
				<Collapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
					<Panel className="show-general-settings" header="General" key="1" forceRender>
						<p>
							This information governs what listeners will see about your podcast on streaming channels like Apple Podcasts:
							from the show art, to whether a podcast shows up in relevant searches, to episode titles and descriptions.
						</p>
						<Form.Item
							name={FIELD_NAMES.TITLE}
							label={LABEL_NAMES.TITLE}
							tooltip={{
								placement: "right",
								title: "It’s important to have a clear, concise name for your podcast. Be sure to make your title specific."
							}}
							rules={[
								{
									required: true,
									message: TITLE_EMPTY_ERROR_MESSAGE
								},
								{
									max: 50,
									message: TITLE_TOO_LONG_ERROR_MESSAGE
								}
							]}
						>
							<Input name={FIELD_NAMES.TITLE} placeholder="Name of Podcast" size="large" />
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.SUBTITLE}
							label={LABEL_NAMES.SUBTITLE}
							tooltip={{
								placement: "right",
								title:
									"Your subtitle is displayed on your Podpal Podcast Profile and gives additional context to your show. It cannot exceed 250 characters. "
							}}
							rules={[
								{
									max: 250,
									message: SUBTITLE_TOO_LONG_ERROR_MESSAGE
								}
							]}
						>
							<TextArea
								name={FIELD_NAMES.SUBTITLE}
								rows={3}
								placeholder="Provide a short phrase or sentence that gives more context to your title"
								size="large"
								showCount
								maxLength={125}
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.SHOW_SUMMARY}
							label={LABEL_NAMES.SHOW_SUMMARY}
							tooltip={{
								placement: "right",
								title:
									"Your show summary should contain one or more sentences describing your podcast to potential listeners. It cannot exceed 4000 characters."
							}}
							rules={[
								{
									required: true,
									validator: async (rule, value) => {
										if (!contentLength) {
											return Promise.reject(SHOW_SUMMARY_EMPTY_ERROR_MESSAGE)
										} else if (contentLength > 4000) {
											return Promise.reject(SHOW_SUMMARY_TOO_LONG_ERROR_MESSAGE)
										} else {
											return Promise.resolve()
										}
									}
								}
							]}
						>
							<RichTextEditor
								placeholder="Tell us more about your show."
								setValue={handleShowSummaryChange}
								defaultValue={data?.podcast?.showSummary}
								height="200px"
								setLength={setShowSummaryLength}
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.PROFILE_URL}
							label={LABEL_NAMES.PROFILE_URL}
							tooltip={{
								placement: "right",
								title:
									"Your custom Podcast Profile URL links to your Podpal podcast page. It must contain 3-50 letters, numbers, or dashes. Please do not use spaces, symbols, or special characters."
							}}
							hasFeedback
							className="podcast-profile-url-field"
							rules={[
								{
									required: true,
									message: PROFILE_URL_EMPTY_ERROR_MESSAGE
								},
								{
									min: 3,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									max: 50,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									pattern: /^[a-zA-Z0-9-]{1,}$/,
									message: PROFILE_URL_INVALID_ERROR_MESSAGE
								},
								{
									transform: value => {
										form.setFields([
											{
												name: FIELD_NAMES.PROFILE_URL,
												value: value.toLowerCase()
											}
										])
									}
								},
								{
									validator: handleValidatePodcastURL
								}
							]}
						>
							<Input
								addonBefore={isMobile ? null : "app.podpal.com/pod/"}
								size="large"
								addonAfter={
									<PodpalButton className="profile-url-visit-button" type="primary" size="large">
										<Link to={`${POD}/${form.getFieldValue(FIELD_NAMES.PROFILE_URL)}`} target="_blank" rel="noopener noreferrer">
											Visit
										</Link>
									</PodpalButton>
								}
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.AUDIO_BIO}
							label={LABEL_NAMES.AUDIO_BIO}
							help={audioErrorMessage}
							validateStatus={audioErrorMessage && "error"}
							tooltip={{
								placement: "right",
								title:
									"Upload a 60-second audio bio so fans and fellow podcasters can learn more about your podcast (MP3 or M4A required)."
							}}
						>
							<AudioUpload
								localAudio={localAudio}
								webAudio={webAudio}
								validateAudioFile={validateAudioFile}
								handleRemove={handleRemoveLocalAudio}
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.COLOR_PICKER}
							label={LABEL_NAMES.COLOR_PICKER}
							tooltip={{
								placement: "right",
								title: "This color is used as the theme on your podcast's public profile or (Pod Page)."
							}}
						>
							<ColorPicker
								selectedColor={selectedColor}
								handleColorPickerClose={handleColorPickerClose}
								showColorPicker={showColorPicker}
								handleColorPickerClick={handleColorPickerClick}
								handleColorChange={handleColorChange}
							/>
						</Form.Item>
						<Row>
							<Col xs={24} sm={8}>
								<Form.Item
									name={FIELD_NAMES.MEDIA_FORMAT}
									label={LABEL_NAMES.MEDIA_FORMAT}
									tooltip={{
										placement: "right",
										title: (
											<div>
												<div>
													Indicates the type of media format of your show. The chosen format type for this podcast cannot be changed
													after podcast creation.
												</div>
												<br />
												<div>
													<strong>Audio</strong>
												</div>
												<ul>
													<li>Supported by all podcast streaming channels.</li>
													<li>M4A & MP3 file format.</li>
												</ul>
												<div>
													<strong>Video</strong>
												</div>
												<ul>
													<li>Only supported by Apple Podcasts.</li>
													<li>MOV, MP4, and M4V file format.</li>
												</ul>
											</div>
										)
									}}
									rules={[
										{
											required: true,
											message: MEDIA_FORMAT_REQUIRED
										}
									]}
								>
									<PodpalRadioButtonGroup
										disabled
										value={data?.podcast?.mediaFormat}
										defaultValue={data?.podcast?.[FIELD_NAMES.MEDIA_FORMAT] ?? data?.mediaFormat}
										onChange={e => {
											addChange(FIELD_NAMES.MEDIA_FORMAT, e.target.value)
										}}
										size="large"
										buttons={podcastMediaRadioButtons}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={8}>
								<Form.Item
									name={FIELD_NAMES.PARENTAL_ADVISORY}
									label={LABEL_NAMES.PARENTAL_ADVISORY}
									rules={[
										{
											required: true,
											message: PARENTAL_ADVISORY_REQUIRED
										}
									]}
									tooltip={{
										placement: "right",
										title: (
											<span>
												Indicates the default explicit setting of your show. Note that this can be changed on a per episode basis in
												episode settings.{" "}
												<ul>
													<li>
														<strong>Clean</strong> indicates that the episode does not contain explicit language or adult content.
														Apple Podcasts displays a “Clean” parental advisory graphic for your episode.
													</li>
													<li>
														<strong>Explicit</strong> indicates the presence of explicit content. Apple Podcasts displays an
														“Explicit” parental advisory graphic for your episode.
													</li>
												</ul>
											</span>
										)
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.PARENTAL_ADVISORY}
										defaultValue={data?.podcast?.[FIELD_NAMES.PARENTAL_ADVISORY] ?? "clean"}
										value={data?.podcast?.parentalAdvisory}
										onChange={e => {
											addChange(FIELD_NAMES.PARENTAL_ADVISORY, e.target.value)
										}}
										size="large"
										buttons={podcastParentalAdvisoryRadioButtons}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={8}>
								<Form.Item
									name={FIELD_NAMES.UPDATE_STATUS}
									label={LABEL_NAMES.UPDATE_STATUS}
									tooltip={{
										placement: "right",
										title: (
											<span>
												The podcast update status.{" "}
												<ul>
													<li>
														<strong>Complete</strong> - select if you will not publish any more episodes in the future.
													</li>
													<li>
														<strong>Active</strong> - select if you are still adding episodes to this podcast.
													</li>
												</ul>
											</span>
										)
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.UPDATE_STATUS}
										defaultValue={data?.podcast?.isComplete ? UPDATE_STATUS_OPTIONS.COMPLETE : UPDATE_STATUS_OPTIONS.ACTIVE}
										value={data?.podcast?.updateStatus}
										onChange={e => {
											addChange(FIELD_NAMES.UPDATE_STATUS, e.target.value)
										}}
										size="large"
										buttons={podcastUpdateStatusRadioButtons}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Panel>
					<Panel className="show-discovery-settings" header="Discovery" key="2" forceRender>
						<p>
							This information further assists with podcast discovery and matching your show with advertisers and sponsors on
							Podpal. It’s important to choose keywords that accurately reflect your podcast topic, theme/style, and audience
							interests.
						</p>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.CATEGORIES}
									label={LABEL_NAMES.CATEGORIES}
									className="tree-select-form-item"
									rules={[
										{
											required: true,
											message: CATEGORY_UNSELECTED_ERROR_MESSAGE,
											type: "array"
										},
										{
											max: 3,
											message: CATEGORY_TOO_MANY_SELECTED_ERROR_MESSAGE,
											type: "array"
										}
									]}
									tooltip={{
										placement: "right",
										title:
											"Choose up to 3 categories that best reflect the content of your show. Note: Apple Podcasts will use the first category in this list to categorize your show. "
									}}
								>
									<BasicTreeSelect
										size="large"
										treeDefaultExpandAll
										value={data?.podcast?.categories?.map(cat => ({ label: cat, value: cat }))}
										treeData={podcastCategoriesData}
										onChange={handleMultipleTreeSelectChange}
										placeholder="Please Select"
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.LANGUAGE}
									label={LABEL_NAMES.LANGUAGE}
									className="tree-select-form-item"
									rules={[
										{
											required: true,
											message: LANGUAGE_UNSELECTED_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title: "The language spoken on your show."
									}}
								>
									<BasicSelect
										placeholder="Please Select"
										size="large"
										options={LANGUAGE_CODES}
										showSearch
										filterOption={(input, option) => option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							name={FIELD_NAMES.KEYWORDS}
							label={LABEL_NAMES.KEYWORDS}
							rules={[
								{
									max: 25,
									message: KEYWORDS_TOO_MANY_ADDED_ERROR_MESSAGE,
									type: "array"
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"Add keywords that help to further categorize your show for discovery and sponsorship. Can include theme, topics, locations, guest names, etc."
							}}
						>
							<Select
								placeholder="Add keywords to help people discover your show!"
								mode="tags"
								size="large"
								dropdownStyle={{ display: "none" }}
								tokenSeparators={[","]}
							/>
						</Form.Item>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.EXTERNAL_WEBSITE}
									label={LABEL_NAMES.EXTERNAL_WEBSITE}
									className="tree-select-form-item"
									rules={[
										{
											type: "url",
											message: EXTERNAL_WEBSITE_INVALID_URL_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title:
											"This is the website associated with your podcast. If not specified, we default this to your Podcast Profile URL above. It can be changed to any URL you like, including your website, blog, or Facebook page."
									}}
								>
									<Input placeholder="https://www.podpal.com/pod" size="large" />
								</Form.Item>
							</Col>

							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.PODCAST_EMAIL}
									label={LABEL_NAMES.PODCAST_EMAIL}
									className="tree-select-form-item"
									rules={[
										{
											type: "email",
											message: EMAIL_INVALID_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title:
											"This is the email associated with your podcast. It represents the email that you would prefer to be contacted for inquiries about your show or guest interview invitations from other Podpal users."
									}}
								>
									<Input placeholder="podcast@domain.com" size="large" />
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col xs={24} md={8} lg={8}>
								<Form.Item
									name={FIELD_NAMES.ITUNES_VISIBILITY}
									label={LABEL_NAMES.ITUNES_VISIBILITY}
									tooltip={{
										placement: "right",
										title:
											"The podcast show or hide status in iTunes. Select “Hide” if you want your show removed from the Apple Podcast directory."
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.ITUNES_VISIBILITY}
										className={itunesVisibilityClassname}
										defaultValue={data?.podcast?.[FIELD_NAMES.ITUNES_VISIBILITY] ?? ITUNES_VISIBILITY_OPTIONS.SHOW}
										onChange={e => {
											addChange(FIELD_NAMES.ITUNES_VISIBILITY, e.target.value)
										}}
										value={data?.podcast?.itunesVisibility}
										size="large"
										buttons={podcastItunesVisiblityRadioButtons}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Panel>
					<Panel
						className="show-schedule-publishing-preferences-settings"
						header="Schedule & Publishing Preferences"
						key="3"
						forceRender
					>
						<p>
							By specifiying your schedule and publishing preferences below, we’re able to help you plan and publish your
							episodes automatically!
						</p>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									name={FIELD_NAMES.COUNTRY}
									label={LABEL_NAMES.COUNTRY}
									className="tree-select-form-item"
									rules={[
										{
											required: true,
											message: COUNTRY_UNSELECTED_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title: "The country where your podcast is being recorded/produced."
									}}
								>
									<BasicSelect
										size="large"
										placeholder="Please Select"
										options={COUNTRY_CODES}
										showSearch
										filterOption={(input, option) => option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={24} md={8} lg={8}>
								<Form.Item
									name={FIELD_NAMES.SHOW_TYPE}
									label={LABEL_NAMES.SHOW_TYPE}
									tooltip={{
										placement: "right",
										title: (
											<ul>
												<li>
													<strong>Episodic Podcasts</strong> (like sports and current events) order episodes from last to first, with
													the most recent recording at the top.
												</li>
												<li>
													<strong>Serial Podcasts</strong> (like storytelling, where the listening order is important) order the
													episodes from first to last similar to how you would experience reading a book.
												</li>
											</ul>
										)
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.SHOW_TYPE}
										defaultValue={data?.podcast?.[FIELD_NAMES.SHOW_TYPE] ?? "episodic"}
										onChange={e => {
											addChange(FIELD_NAMES.SHOW_TYPE, e.target.value)
										}}
										value={data?.podcast?.showType}
										size="large"
										buttons={podcastShowTypeRadioButtons}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8} lg={8}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_GROUPING}
									label={LABEL_NAMES.EPISODE_GROUPING}
									tooltip={{
										placement: "right",
										title: (
											<ul>
												<li>
													<strong>None</strong> indicates that you don’t plan on grouping episodes into seasons.
												</li>
												<li>
													<strong>Seasons</strong> says that you would like to break your episodes into seasons using season numbers.
												</li>
											</ul>
										)
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.EPISODE_GROUPING}
										defaultValue={data?.podcast?.[FIELD_NAMES.EPISODE_GROUPING] ?? "none"}
										value={data?.podcast?.episodeGrouping}
										onChange={e => {
											addChange(FIELD_NAMES.EPISODE_GROUPING, e.target.value)
										}}
										size="large"
										buttons={podcastEpisodeGroupingRadioButtons}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8} lg={8}>
								<Form.Item
									name={FIELD_NAMES.EPISODE_NUMBERING}
									label={LABEL_NAMES.EPISODE_NUMBERING}
									tooltip={{
										placement: "right",
										title: (
											<ul>
												<li>
													<strong>No</strong> indicates that you don’t want to associate a number with each episode.
												</li>
												<li>
													<strong>Yes</strong> says that you would like use episode numbers to catalog your show in podcast streaming
													channels like Apple Podcasts.
												</li>
											</ul>
										)
									}}
								>
									<PodpalRadioButtonGroup
										name={FIELD_NAMES.EPISODE_NUMBERING}
										defaultValue={data?.podcast?.[FIELD_NAMES.EPISODE_NUMBERING] ?? "yes"}
										value={data?.podcast?.episodeNumbering}
										onChange={e => {
											addChange(FIELD_NAMES.EPISODE_NUMBERING, e.target.value)
										}}
										size="large"
										buttons={podcastEpisodeNumberingRadioButtons}
									/>
								</Form.Item>
							</Col>
						</Row>
						{/* <Row justify="start" gutter={0}>
							<Col xs={24} md={6} lg={6}>
								<Form.Item
									valuePropName="checked"
									name={FIELD_NAMES.AUTO_SCHEDULE}
									label={LABEL_NAMES.AUTO_SCHEDULE}
									tooltip={{
										placement: "right",
										title:
											"Turn on if you would like Podpal to auto-schedule episodes based on your default preferences below.",
									}}
								>
									<PrimarySwitch
										checked={form.getFieldValue(FIELD_NAMES.AUTO_SCHEDULE) ?? false}
										onChange={(checked) => {
											setAutoSchedule(checked)
											addChange(FIELD_NAMES.AUTO_SCHEDULE, checked)
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={18} lg={18}>
								<p className="toggle-paragraph-text">
									When auto-scheduling is toggled on, Podpal will use your default release cadence, day, and time
									preferences to determine the <strong>estimated </strong>
									release date of newly created episodes. You can always change the release date later, but this feature
									makes planning your next show fast & easy.
								</p>
							</Col>
						</Row>
						<Form.Item
							name={FIELD_NAMES.DEFAULT_RELEASE_CADENCE}
							label={LABEL_NAMES.DEFAULT_RELEASE_CADENCE}
							tooltip={{
								placement: "right",
								title:
									"Indicates the planned frequency of episode releases. Most podcasts release a new episode each week.",
							}}
						>
							<PodpalRadioButtonGroup
								size="large"
								disabled={!showAutoPublishFields}
								defaultValue={data?.podcast?.[FIELD_NAMES.DEFAULT_RELEASE_CADENCE] ?? "weekly"}
								value={data?.podcast?.defaultReleaseCadence}
								onChange={(e) => {
									addChange(FIELD_NAMES.DEFAULT_RELEASE_CADENCE, e.target.value)
								}}
								buttons={podcastDefaultReleaseCadenceRadioButtons}
								stackedOnMobile
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.DEFAULT_RELEASE_DAY}
							label={LABEL_NAMES.DEFAULT_RELEASE_DAY}
							tooltip={{
								placement: "right",
								title:
									"Indicates the planned day of episode releases. Many podcasters like to give their audience new content on Mondays to start the week.",
							}}
						>
							<PodpalRadioButtonGroup
								size="large"
								disabled={!showAutoPublishFields}
								value={data?.podcast?.defaultReleaseDay}
								defaultValue={data?.podcast?.[FIELD_NAMES.DEFAULT_RELEASE_DAY] ?? "mon"}
								onChange={(e) => {
									addChange(FIELD_NAMES.DEFAULT_RELEASE_DAY, e.target.value)
								}}
								buttons={podcastDefaultReleaseDayRadioButtons}
								stackedOnMobile
							/>
						</Form.Item>
						<Form.Item
							name={FIELD_NAMES.DEFAULT_RELEASE_TIME}
							label={LABEL_NAMES.DEFAULT_RELEASE_TIME}
							rules={[
								{
									required: true,
									message: DEFAULT_RELEASE_TIME_EMPTY_MESSAGE,
								},
							]}
							tooltip={{
								placement: "right",
								title:
									"Indicates the planned time of episode releases. 2 o’clock is sometimes preferred as many podcast listeners like to tune in shortly after lunch.",
							}}
						>
							<PodpalTimePicker
								size="large"
								disabled={!showAutoPublishFields}
								format="h:mm a"
								minuteStep={15}
								use12Hours={true}
							/>
						</Form.Item> */}
						{/* <Row justify="start" gutter={0}>	// TODO: Leave in here in case we need it again.
							<Col xs={24} md={6} lg={6}>
								<Form.Item
									name={FIELD_NAMES.AUTO_PUBLISH}
									label={LABEL_NAMES.AUTO_PUBLISH}
									valuePropName="checked"
									tooltip={{
										placement: "right",
										title:
											"Turn on if you would like Podpal to auto-publish completed episodes according to your scheduled release date.",
									}}
								>
									<PrimarySwitch
										checked={form.getFieldValue(FIELD_NAMES.AUTO_PUBLISH) ?? false}
										onChange={(checked) => {
											addChange(FIELD_NAMES.AUTO_PUBLISH, checked)
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8} lg={18}>
								<p className="toggle-paragraph-text">
									When auto-publishing is toggled on, Podpal will auto-publish
									<strong> completed </strong> episodes according to your scheduled release date. If your episode status
									is 100%, and you’ve assigned a release date (automatically or manually), the episode will publish to
									your RSS feed automatically on that date. Note that you can always publish manually ahead of schedule.
								</p>
							</Col>
						</Row> */}
					</Panel>
					<Panel
						className="show-author-ownership-copyright-settings"
						header="Author, Ownership & Copyright"
						key="4"
						forceRender
					>
						<p>
							This information is used to properly identify your show with its creators and producers. Podpal will also use
							this contact information in the event that we need to reach out to your podcast team.
						</p>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									className="tree-select-form-item"
									name={FIELD_NAMES.OWNER_NAME}
									label={LABEL_NAMES.OWNER_NAME}
									rules={[
										{
											required: true,
											message: OWNER_NAME_REQUIRED
										},
										{
											min: 2,
											message: OWNER_NAME_TOO_SHORT_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title:
											"The individual/group who owns the rights to the content of the show (the Owner). If needed, this info will be used to contact the owner of the podcast. It will not be publicly displayed."
									}}
								>
									<Input placeholder="Owner Name" size="large" />
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12}>
								<Form.Item
									className="tree-select-form-item"
									name={FIELD_NAMES.OWNER_EMAIL}
									label={LABEL_NAMES.OWNER_EMAIL}
									rules={[
										{
											required: true,
											message: EMAIL_INVALID_ERROR_MESSAGE
										},
										{
											type: "email",
											message: EMAIL_INVALID_ERROR_MESSAGE
										}
									]}
									tooltip={{
										placement: "right",
										title:
											"The email of the individual/group who owns the content of the show (the Owner). If needed, this info will be used to contact the owner of the podcast. It will not be publicly displayed."
									}}
								>
									<Input placeholder="owner@domain.com" size="large" />
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							className="tree-select-form-item"
							name={FIELD_NAMES.AUTHORS}
							label={LABEL_NAMES.AUTHORS}
							rules={[
								{
									required: true,
									message: AUTHORS_REQUIRED
								},
								{
									max: 10,
									message: TOO_MANY_AUTHORS_ERROR_MESSAGE,
									type: "array"
								}
							]}
							tooltip={{
								placement: "right",
								title:
									"The individual or group/network responsible for creating the show (the Artist). Providing this information will allow listeners to see all shows created by the same entity."
							}}
						>
							<Select
								placeholder="Author Names"
								mode="tags"
								size="large"
								dropdownStyle={{ display: "none" }}
								tokenSeparators={[","]}
							/>
						</Form.Item>
						<Form.Item
							className="tree-select-form-item"
							name={FIELD_NAMES.COPYRIGHT}
							label={LABEL_NAMES.COPYRIGHT}
							tooltip={{
								placement: "right",
								title:
									"The show copyright details. The name of the individual or organization that owns the copyright to the show content."
							}}
							rules={[
								{
									required: true,
									message: COPYRIGHT_REQUIRED
								}
							]}
						>
							<Input name={FIELD_NAMES.COPYRIGHT} placeholder="e.g. John Doe, 2020" size="large" />
						</Form.Item>
					</Panel>
					<Panel className="show-delete-podcast-settings" header="Delete Podcast" key="5" forceRender>
						<p>
							Clicking the button below will <strong>permanently delete</strong> your show and all of your episodes from
							Podpal. Your RSS feed will be gone forever. Once you delete your podcast, there’s no going back.
						</p>
						{podcastRoleData?.podcastRole?.showAccess !== undefined ? (
							<Tooltip title="You must be the owner of a podcast to delete it.">
								<PodpalButton size="large" disabled>
									Delete Podcast
								</PodpalButton>
							</Tooltip>
						) : (
							<PodpalButton danger variant="outlined" size="large" onClick={() => dispatch(toggleDeletePodcastModalVisible())}>
								Delete Podcast
							</PodpalButton>
						)}
					</Panel>
				</Collapse>
				<footer className="footer-actions">
					<Row gutter={8} justify="start" align="middle">
						<Col>
							<PodpalButton
								type="primary"
								size="large"
								loading={isLoading}
								htmlType="submit"
								disabled={!isModified && !showSummaryModified}
							>
								Save
							</PodpalButton>
						</Col>
						<Col>
							<PodpalButton
								variant="secondary"
								size="large"
								onClick={handleCancel}
								disabled={!isModified && !showSummaryModified}
							>
								Cancel
							</PodpalButton>
						</Col>
					</Row>
				</footer>
			</Form>
			<Prompt when={isModified} message={() => window.confirm("Do you want to leave without saving?")} />
		</div>
	)
}
