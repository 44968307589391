import PodpalCard from "components/common/Cards/PodpalCard"
import React, { useContext } from "react"
import { Avatar, Row, Col, Dropdown, Menu, Space, Divider } from "antd"
import { EpisodeManagementTooltip } from "./EpisodeManagementTooltip"
import styled, { DefaultTheme, ThemeContext } from "styled-components"
import {
	AcceptReviewIcon,
	AddReviewIcon,
	ExpandIcon,
	InReviewIcon,
	ReviewNotStartedIcon,
	ReviseReviewIcon
} from "assets/icons/Episodes"
import { useEpisodeReviews } from "services/queries/EpisodeReview"
import { EpisodeReview, ReviewStatus, ReviewType, UserWithEpisodeReview } from "services/types/episodeReview"
import { UserIcon } from "assets/icons/Common/User"
import { useParams } from "react-router"
import { createEpisodeReview, deleteEpisodeReview, updateEpisodeReview } from "services/episodeReview.service"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { useQueryClient } from "react-query"
import { toTitleCase } from "utils/titleCase"
import { useSelector } from "react-redux"
import { RootState } from "index"
import { usePodcastTeam } from "services/queries/Podcasts"
import { UserWithPodcastRole } from "services/types/user"
import { useEpisode } from "services/queries/Episodes"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import { updateEpisodeProgress } from "services/episodeProgress.service"

const StyledPodpalCard = styled(PodpalCard)`
	max-width: 769px;
	padding: 0;
`

const StyledTable = styled.table`
	width: 100%;
	padding-left: 17.5%;
	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		padding-left: 10%;
	}
	border-collapse: separate;
	border-spacing: 1em;
	color: ${({ theme }) => theme.textSecondary};
	font: ${({ theme }) => theme.fontSm};

	tr:first-child {
		border-bottom: 1px solid ${({ theme }) => theme.gray4};
	}

	tr {
		th,
		td {
			text-align: start;
		}
	}

	th,
	td {
		width: 33%;
	}

	.ant-dropdown {
		cursor: pointer;
	}
`

const Clickable = styled(Space)`
	cursor: pointer;
`

const ReviewStatusMenu = styled(Menu)`
	li {
		display: flex;
		// First icon is a span, so need to style separately.
		&:first-child {
			span {
				margin-right: 8px;
			}
		}
	}
`

interface ReviewFooterProps {
	background: string
}

const StyledReviewFooter = styled.div.attrs((props: ReviewFooterProps) => ({
	background: props.background
}))`
	background: ${({ background }) => background};
	text-align: center;
	border-radius: 0 0 ${({ theme }) => theme.radiusLg} ${({ theme }) => theme.radiusLg};
	padding: ${({ theme }) => theme.spaceMd} 0;
`

interface TextProps {
	font: keyof DefaultTheme
	color: DefaultTheme
}

const Text = styled.span.attrs((props: TextProps) => ({
	font: props.font,
	color: props.color
}))`
	font: ${({ font }) => font};
	color: ${({ color }) => color};
`

export interface EpisodeReviewProps {
	className: string
}

export function EpisodeReviewCard({ className }: EpisodeReviewProps) {
	const theme = useContext(ThemeContext)
	const queryClient = useQueryClient()

	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const { episodeId } = useParams<{ episodeId: string }>()

	const { data: episodeReviewsData } = useEpisodeReviews(episodeId)
	const { data: episodeData } = useEpisode(episodeId)
	const { data: episodeProgressData } = useEpisodeProgress(episodeId)
	const { data: podcastTeamData } = usePodcastTeam(selectedPodcast?.id)

	const {
		setup,
		inviteGuests,
		scheduleInterview,
		uploadAudio,
		writeShowNotes,
		reviewEpisode
	} = episodeProgressData?.episodeProgress

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async (progressStep: string) => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.COMPLETED
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.COMPLETED })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleMarkAsInProgress = async (progressStep: string) => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.IN_PROGRESS
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
			queryClient.invalidateQueries(["episode", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleReviewProgress = async (updatedReview: EpisodeReview, isNewReview: boolean) => {
		if (episodeData?.episode.isPublished) {
			return
		}

		if (isNewReview) {
			handleMarkAsInProgress("reviewEpisode")
		}

		let hasRevisions, hasPending
		if (episodeReviewsData?.usersWithEpisodeReviews) {
			for (let i = 0; i < episodeReviewsData?.usersWithEpisodeReviews.length; i++) {
				let userWithEpisodeReview = episodeReviewsData?.usersWithEpisodeReviews[i]
				let reviewStatus
				if (userWithEpisodeReview.episodeReview.id === updatedReview.id) {
					// Handle case where the updated review was deleted.
					if (updatedReview.isDeleted) {
						continue
					}
					reviewStatus = updatedReview.reviewStatus
				} else {
					reviewStatus = userWithEpisodeReview.episodeReview.reviewStatus
				}
				if (reviewStatus === ReviewStatus.revise) {
					hasRevisions = true
				} else if (reviewStatus === ReviewStatus.accept) {
				} else {
					hasPending = true
				}
			}
		}

		if (hasRevisions || hasPending) {
			handleMarkAsInProgress("reviewEpisode")
		} else {
			handleMarkAsDone("reviewEpisode")
		}
	}

	const REVIEW_TYPE = [
		{ label: "Final Review", value: ReviewType.finalReview },
		{ label: "Episode Setup", value: ReviewType.episodeSetup },
		{ label: "Audio Quality", value: ReviewType.audioQuality },
		{ label: "Video Quality", value: ReviewType.videoQuality },
		{ label: "Show Notes", value: ReviewType.showNotes }
	]

	const mapToReviewType = (key: React.Key): ReviewType => {
		switch (key) {
			case ReviewType.episodeSetup:
				return ReviewType.episodeSetup
			case ReviewType.audioQuality:
				return ReviewType.audioQuality
			case ReviewType.videoQuality:
				return ReviewType.videoQuality
			case ReviewType.showNotes:
				return ReviewType.showNotes
			default:
				return ReviewType.finalReview
		}
	}

	const REVIEW_STATUS = [
		{ label: "Not Started", value: ReviewStatus.notStarted },
		{ label: "In Review", value: ReviewStatus.inReview },
		{ label: "Revise", value: ReviewStatus.revise },
		{ label: "Accept", value: ReviewStatus.accept }
	]

	const mapToReviewStatus = (key: React.Key): ReviewStatus => {
		switch (key) {
			case ReviewStatus.inReview:
				return ReviewStatus.inReview
			case ReviewStatus.revise:
				return ReviewStatus.revise
			case ReviewStatus.accept:
				return ReviewStatus.accept
			default:
				return ReviewStatus.notStarted
		}
	}

	const reviewStatusIcon = (reviewStatus: ReviewStatus | undefined) => {
		switch (reviewStatus) {
			case ReviewStatus.inReview:
				return <InReviewIcon />
			case ReviewStatus.revise:
				return <ReviseReviewIcon />
			case ReviewStatus.accept:
				return <AcceptReviewIcon />
			default:
				return <ReviewNotStartedIcon> </ReviewNotStartedIcon>
		}
	}

	interface FooterConfig {
		text: string
		background: string
	}

	const computeFooterConfig = (): FooterConfig => {
		let hasRevisions, hasPending
		if (episodeReviewsData?.usersWithEpisodeReviews) {
			for (let i = 0; i < episodeReviewsData?.usersWithEpisodeReviews.length; i++) {
				let userWithEpisodeReview = episodeReviewsData?.usersWithEpisodeReviews[i]
				if (userWithEpisodeReview.episodeReview.reviewStatus === ReviewStatus.revise) {
					hasRevisions = true
				} else if (userWithEpisodeReview.episodeReview.reviewStatus === ReviewStatus.accept) {
				} else {
					hasPending = true
				}
			}
		}

		if (hasRevisions) {
			return {
				text: "Revisions Needed",
				background: theme.podpalRedLight
			}
		} else if (hasPending) {
			return {
				text: "Pending Review",
				background: theme.podpalYellowLight
			}
		}
		return {
			text: "Review Complete",
			background: theme.podpalGreenLight
		}
	}

	const footerConfig = computeFooterConfig()

	return (
		<StyledPodpalCard className={className}>
			<Row justify="end" align="middle" style={{ paddingTop: theme.spaceLg, paddingRight: theme.spaceLg }}>
				<EpisodeManagementTooltip tooltipText="Add a review, and assign a team member to ensure this episode meets your quality standards." />
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Text font={theme.fontMd} color={theme.textPrimary}>
					Episode Review
				</Text>
				<AddReviewIcon
					style={{ marginLeft: theme.spaceXs }}
					onClick={async () => {
						try {
							const newEpisodeReview = {
								reviewType: ReviewType.episodeSetup,
								episodeId
							}
							await createEpisodeReview(newEpisodeReview)
							await queryClient.invalidateQueries(["episodeReviews", episodeId])
							handleReviewProgress(newEpisodeReview, true)
						} catch (e) {
							errorAlert("Failed to add a new review.")
						}
					}}
				/>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col span={24}>
					<StyledTable>
						<tr>
							<th>Review Type</th>
							<th>Reviewer</th>
							<th>Status</th>
						</tr>
						{episodeReviewsData?.usersWithEpisodeReviews?.map((userWithEpisodeReview: UserWithEpisodeReview) => (
							<tr>
								<td>
									<Dropdown
										overlay={
											<Menu
												triggerSubMenuAction="click"
												onClick={async ({ key }) => {
													try {
														await updateEpisodeReview({
															...userWithEpisodeReview.episodeReview,
															reviewType: mapToReviewType(key)
														})
														queryClient.invalidateQueries(["episodeReviews", episodeId])
														successAlert("Changes Saved.")
													} catch (e) {
														errorAlert("Failed to update review type.")
													}
												}}
											>
												{REVIEW_TYPE.map(reviewType => (
													<Menu.Item key={reviewType.value}>{reviewType.label}</Menu.Item>
												))}
											</Menu>
										}
										trigger={["click"]}
										placement="bottomCenter"
									>
										<Clickable size={4}>
											{userWithEpisodeReview?.episodeReview?.reviewType
												? toTitleCase(userWithEpisodeReview.episodeReview.reviewType)
												: "Final Review"}
											<ExpandIcon />
										</Clickable>
									</Dropdown>
								</td>
								<td>
									<Dropdown
										overlay={
											<Menu triggerSubMenuAction="click" onClick={async ({ key }) => {}}>
												{podcastTeamData?.usersWithPodcastRoles.map((userWithPodcastRole: UserWithPodcastRole) => (
													<Menu.Item
														key={userWithPodcastRole.user.id}
														onClick={async () => {
															try {
																await updateEpisodeReview({
																	...userWithEpisodeReview.episodeReview,
																	reviewerId: userWithPodcastRole.user.id
																})
																queryClient.invalidateQueries(["episodeReviews", episodeId])
																successAlert("Changes Saved.")
															} catch (e) {
																errorAlert("Failed to update reviewer.")
															}
														}}
													>
														<Space>
															{userWithPodcastRole?.user?.thumbnailUrl ? (
																<Avatar src={userWithPodcastRole.user.thumbnailUrl} />
															) : (
																<Avatar icon={<UserIcon />} />
															)}
															{userWithPodcastRole.user.firstName + " " + userWithPodcastRole.user.lastName}
														</Space>
													</Menu.Item>
												))}
											</Menu>
										}
										trigger={["click"]}
									>
										<Clickable size={4}>
											{userWithEpisodeReview?.user?.thumbnailUrl ? (
												<Avatar size={40} shape="circle" src={userWithEpisodeReview.user.thumbnailUrl} />
											) : (
												<Avatar size={40} shape="circle" icon={<UserIcon />} />
											)}
											<ExpandIcon />
										</Clickable>
									</Dropdown>
								</td>
								<td>
									<Dropdown
										overlay={
											<ReviewStatusMenu triggerSubMenuAction="click">
												{REVIEW_STATUS.map(reviewStatus => (
													<Menu.Item
														key={reviewStatus.value}
														icon={reviewStatusIcon(reviewStatus.value)}
														onClick={async () => {
															try {
																const newEpisodeReview = {
																	...userWithEpisodeReview.episodeReview,
																	reviewStatus: mapToReviewStatus(reviewStatus.value)
																}
																await updateEpisodeReview(newEpisodeReview)
																await queryClient.invalidateQueries(["episodeReviews", episodeId])
																handleReviewProgress(newEpisodeReview, false)
																successAlert("Changes Saved.")
															} catch (e) {
																errorAlert("Failed to update review type.")
															}
														}}
													>
														{reviewStatus.label}
													</Menu.Item>
												))}
												<Divider style={{ margin: 0 }} />
												<Menu.Item
													key="delete"
													danger
													onClick={async () => {
														try {
															await deleteEpisodeReview(userWithEpisodeReview.episodeReview.id)
															await queryClient.invalidateQueries(["episodeReviews", episodeId])
															handleReviewProgress(
																{
																	...userWithEpisodeReview.episodeReview,
																	isDeleted: true
																},
																false
															)
															successAlert("Changes Saved.")
														} catch (e) {
															errorAlert("Failed to remove review.")
														}
													}}
												>
													Delete Review
												</Menu.Item>
											</ReviewStatusMenu>
										}
										trigger={["click"]}
									>
										<Clickable size={2}>
											{reviewStatusIcon(userWithEpisodeReview?.episodeReview?.reviewStatus)}
											<ExpandIcon />
										</Clickable>
									</Dropdown>
								</td>
							</tr>
						))}
					</StyledTable>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<StyledReviewFooter background={footerConfig.background}>
						<Text font={theme.fontMd} color={theme.neutral0}>
							{footerConfig.text}
						</Text>
					</StyledReviewFooter>
				</Col>
			</Row>
		</StyledPodpalCard>
	)
}
