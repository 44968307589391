import React from "react"
import { useDispatch } from "react-redux"
import { Badge, Divider, Menu, Popover } from "antd"
import { HelpCenterIcon } from "assets/icons/Navbar"
import "./HelpCenterDropdown.scss"
import { useSelector } from "react-redux"
import { toggleHelpCenterDropdown } from "redux/actions/HelpCenter"
import { RootState } from "index"

export function HelpCenterDropdown() {
	const dispatch = useDispatch()

	// TODO - this is for the red "notification" dot commented out below. To be revisited later
	// const newUpdates = useSelector((state: RootState) => state.helpCenter.newUpdates)
	const visible = useSelector((state: RootState) => state.helpCenter.helpCenterDropdownVisible)

	const helpCenterOverlay = (
		<div className="help-center-overlay">
			<div className="top-bar-popover-header-container">
				<div className="top-bar-popover-header-row">
					<div className="top-bar-popover-header-col">
						<div className="help-center-text">Help Center</div>
					</div>
				</div>
			</div>
			<Divider className="top-bar-popover-header-divider" />
			<Menu selectedKeys={[]}>
				<Menu.Item>
					<a
						href="https://www.podpal.com/video-tutorials"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => dispatch(toggleHelpCenterDropdown())}
					>
						Video Tutorials
					</a>
				</Menu.Item>

				<Menu.Item>
					<a
						href="https://www.podpal.com/category/podcast-tips"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => dispatch(toggleHelpCenterDropdown())}
					>
						Podcast Tips
					</a>
				</Menu.Item>

				<Menu.Item>
					<a
						href="https://forms.gle/mHMtB7gou1TiKDJs7"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => dispatch(toggleHelpCenterDropdown())}
					>
						Give Feedback
					</a>
				</Menu.Item>

				<Divider className="top-bar-popover-menu-divider" />
				<Menu.Item>
					<a
						href="http://helpcenter.podpal.com/en/"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => dispatch(toggleHelpCenterDropdown())}
					>
						Help & FAQ's
					</a>
				</Menu.Item>
			</Menu>
		</div>
	)

	return (
		<Popover
			className="help-center-dropdown"
			content={helpCenterOverlay}
			trigger="click"
			placement="bottomRight"
			arrowPointAtCenter
			visible={visible}
			onVisibleChange={() => dispatch(toggleHelpCenterDropdown())}
		>
			{/* TODO - this is the red "notification" dot. To be revisited later */}
			<Badge /* dot={newUpdates.length > 0} */>
				<HelpCenterIcon className="help-center-icon" />
			</Badge>
		</Popover>
	)
}
