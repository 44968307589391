import { RootState } from "index"
import { Form, Row, Col, Input, Select, Alert } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PodpalModal from "../../PodpalModal"
import { toggleInviteTeamModalVisible } from "redux/actions/TeamManagement"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { usePodcastInfo } from "services/queries/PodcastInfo"
import styled from "styled-components"
import { FormListFieldData } from "antd/lib/form/FormList"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import invitesSentImage from "assets/teammanagement/invitessent.svg"
import { CollaboratorType, PodcastRole, ShowAccess } from "services/types/podcastRoles"
import { inviteTeam } from "services/teamManagement.service"
import { useQueryClient } from "react-query"
import { mapSubscriptionTypeToPodcastLimit } from "constants/planLimits"
import { useOwnerUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"
import { Link } from "react-router-dom"
import { OFFERS_AND_UPGRADES, SETTINGS, SUBSCRIPTION_AND_BILLING } from "constants/routes"
import { InfoCircleOutlined } from "@ant-design/icons"
import colors from "styles/scss/color.scss"

const AddInvite = styled.span`
	font-size: ${({ theme }) => theme.spaceLg};
	background: ${({ theme }) => theme.podpalBlueGhost};
	margin-left: ${({ theme }) => theme.spaceMd};
	color: ${({ theme }) => theme.textSecondary};
	border-radius: 200px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

const StyledPrimaryHeader = styled.span`
	color: ${({ theme }) => theme.textPrimary};
	font: ${({ theme }) => theme.fontLg};
`

const StyledSecondaryText = styled.span`
	color: ${({ theme }) => theme.gray7};
	font: ${({ theme }) => theme.fontSm};
`

const StyledAlert = styled(Alert)`
	background: ${({ theme }) => theme.podpalBlueGhost};
	a {
		color: ${({ theme }) => theme.podpalBlue};
		text-decoration: underline;
	}
	margin-bottom: ${({ theme }) => theme.spaceLg};
`

const StyledPodpalModal = styled(PodpalModal)`
	.ant-modal-body {
		max-height: 472px;
		overflow-y: scroll;
	}
`

export function InviteTeamModal() {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	const visible = useSelector((state: RootState) => state.teamManagement.inviteTeamModalVisible)
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const { data: podcastInfoData } = usePodcastInfo(selectedPodcast?.id)
	const { data: userInfoData } = useOwnerUserInfo(selectedPodcast?.id)
	const planLimits = mapSubscriptionTypeToPodcastLimit(
		userInfoData ? userInfoData.userInfo.subscriptionType : SubscriptionType.free
	)
	const subscriptionType = userInfoData?.userInfo?.subscriptionType || SubscriptionType.free

	const SEQUENCE_NAMES = {
		FORM: "FORM",
		CONFIRMATION: "CONFIRMATION"
	}

	const [loading, setLoading] = useState(false)
	const [count, setCount] = useState(0)
	const [sequence, setSequence] = useState<string>(SEQUENCE_NAMES.FORM)

	useEffect(() => {
		if (podcastInfoData?.podcastInfo.numberOfCollaborators) {
			setCount(podcastInfoData.podcastInfo.numberOfCollaborators)
		}
	}, [podcastInfoData])

	const convertToCollaboratorType = (collaboratorType: string): CollaboratorType => {
		switch (collaboratorType) {
			case "Co-Host":
				return CollaboratorType.coHost
			case "Producer":
				return CollaboratorType.producer
			case "Audio Engineer":
				return CollaboratorType.audioEngineer
			case "Copywriter":
				return CollaboratorType.copywriter
			case "Marketing Manager":
				return CollaboratorType.marketingManager
			default:
				return CollaboratorType.host
		}
	}

	const TEAM_ROLE_OPTIONS = podcastInfoData?.podcastInfo.teamRoles
		? podcastInfoData.podcastInfo.teamRoles.map(role => ({
				label: role,
				value: convertToCollaboratorType(role)
		  }))
		: [
				{ label: "Host", value: CollaboratorType.host },
				{ label: "Co-Host", value: CollaboratorType.coHost },
				{ label: "Producer", value: CollaboratorType.producer },
				{ label: "Audio Engineer", value: CollaboratorType.audioEngineer },
				{ label: "Copywriter", value: CollaboratorType.copywriter },
				{ label: "Marketing Manager", value: CollaboratorType.marketingManager }
		  ]

	const SHOW_ACCESS_OPTIONS = [
		{ label: "Admin", value: ShowAccess.admin },
		{ label: "Editor", value: ShowAccess.editor }
	]

	const handleClose = () => {
		dispatch(toggleInviteTeamModalVisible())
		form.resetFields()
		setCount(0)
		setSequence(SEQUENCE_NAMES.FORM)
	}

	const handleSendInvitations = async () => {
		setLoading(true)
		try {
			await form.validateFields()
			const invitations = form.getFieldValue("invitations")
			const podcastRoles: PodcastRole[] = []
			for (let i = 0; i < invitations.length; i++) {
				const podcastRole: PodcastRole = {
					podcastId: selectedPodcast?.id,
					email: invitations[i].email.toLowerCase(),
					collaboratorType: invitations[i].collaboratorType,
					showAccess: invitations[i].showAccess
				}
				podcastRoles.push(podcastRole)
			}
			await inviteTeam({
				inviterId: authUser.uid,
				podcastRoles
			})
			queryClient.invalidateQueries(["podcastTeam", selectedPodcast?.id])
			queryClient.invalidateQueries(["podcastInfo", selectedPodcast?.id])
			setLoading(false)
			setSequence(SEQUENCE_NAMES.CONFIRMATION)
		} catch (e) {
			setLoading(false)
			errorAlert("Failed to send invitations.")
		}
	}

	const formBody = (
		<>
			{subscriptionType !== SubscriptionType.agency && (
				<StyledAlert
					type="info"
					banner
					message={
						<>
							Free accounts can add a maximum of 3 team members. Paid accounts can add up to 10 collaborators.
							<Link to={`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${OFFERS_AND_UPGRADES}`} onClick={handleClose}>
								Upgrade
							</Link>
						</>
					}
					icon={<InfoCircleOutlined color={colors.podpalBlue} />}
				/>
			)}
			<Form form={form}>
				<Form.List name="invitations">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, fieldKey }: FormListFieldData) => (
								<>
									<Row>
										<Col span={24}>
											<Form.Item
												name={[name, "email"]}
												fieldKey={[fieldKey, "email"]}
												rules={[
													{
														type: "email",
														message: "Invalid email"
													},
													{
														required: true,
														message: "Email is required"
													}
												]}
											>
												<Input size="large" placeholder="name@example.com" />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item
												name={[name, "collaboratorType"]}
												fieldKey={[fieldKey, "collaboratorType"]}
												rules={[
													{
														required: true,
														message: "Team role is required."
													}
												]}
											>
												<Select size="large" placeholder="Team Role" options={TEAM_ROLE_OPTIONS} />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name={[name, "showAccess"]}
												fieldKey={[fieldKey, "showAccess"]}
												rules={[
													{
														required: true,
														message: "Show access is required."
													}
												]}
											>
												<Select size="large" placeholder="Admin Access" options={SHOW_ACCESS_OPTIONS} />
											</Form.Item>
										</Col>
									</Row>
								</>
							))}
							<Row justify="center" align="middle">
								<Col>
									<AddInvite
										onClick={() => {
											if (count >= planLimits.collaborators) {
												errorAlert("You've reached the maximum number of collaborators for your podcast.")
											} else {
												setCount(prevState => prevState + 1)
												add()
											}
										}}
									>
										+
									</AddInvite>
								</Col>
							</Row>
						</>
					)}
				</Form.List>
			</Form>
		</>
	)

	const confirmationBody = (
		<>
			<Row align="middle" justify="center" gutter={[0, 24]}>
				<Col>
					<StyledPrimaryHeader>Invites Sent!</StyledPrimaryHeader>
				</Col>
			</Row>
			<Row align="middle" justify="center" gutter={[0, 24]}>
				<Col>
					<img src={invitesSentImage} alt="invitessent" />
				</Col>
			</Row>
			<Row align="middle" justify="center">
				<Col>
					<StyledSecondaryText>
						<strong>Two heads are better than one.</strong>
					</StyledSecondaryText>
				</Col>
			</Row>
			<Row justify="center" align="middle">
				<Col>
					<StyledSecondaryText>Podcasting is always better with friends!</StyledSecondaryText>
				</Col>
			</Row>
		</>
	)

	const formFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton key="sendinvitations" loading={loading} type="primary" onClick={handleSendInvitations}>
			Send Invitations
		</PodpalButton>
	]

	const confirmationFooter = [
		<PodpalButton key="ok" type="primary" onClick={handleClose}>
			OK
		</PodpalButton>
	]

	let body, footer
	switch (sequence) {
		case SEQUENCE_NAMES.FORM:
			body = formBody
			footer = formFooter
			break
		case SEQUENCE_NAMES.CONFIRMATION:
			body = confirmationBody
			footer = confirmationFooter
			break
		default:
			break
	}

	return (
		<StyledPodpalModal
			centered
			destroyOnClose
			width={480}
			title="Invite Your Team"
			visible={visible}
			footer={footer}
			onCancel={handleClose}
		>
			{body}
		</StyledPodpalModal>
	)
}
