import React, { useState, useEffect } from "react"
import { useQueryClient } from "react-query"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, DatePicker, TimePicker, Form, Alert } from "antd"
import { InfoCircleFilled } from "@ant-design/icons"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalModal from "components/common/Modals/PodpalModal"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { useEpisode } from "services/queries/Episodes"
import { setEpisodeLastPublishedDate } from "redux/actions/Episodes/AddNewEpisode"
import {
	setEpisodeIdForLastPublishedDateModal,
	toggleChangeLastPublishedDateModalVisible
} from "redux/actions/Episodes"
import moment from "moment-timezone"
import styled from "styled-components"
import { publishEpisode, updateEpisode } from "services/episode.service"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import useToggle from "hooks/useToggle"

const StyledPodpalModal = styled(PodpalModal)`
	.ant-modal-body {
		padding-top: 0;
	}
`

const ChangeLastPublishedDateModal = () => {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const [lastPublishedDate, setLastPublishedDate] = useState(null)
	const [lastPublishedTime, setLastPublishedTime] = useState(null)
	const [loading, toggleLoading] = useToggle()

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const visible = useSelector(state => state.episode.changeLastPublishedDateModalVisible)
	const episodeId = useSelector(state => state.episode.episodeIdForLastPublishedDate)
	const currentLastPublishedDate = useSelector(state => state.addNewEpisode.lastPublishedDate)
	const { isLoading, isError, data } = useEpisode(episodeId)

	useEffect(() => {
		if (data?.episode?.lastPublishedDate) {
			const date = moment(currentLastPublishedDate)
			setLastPublishedDate(date)
			setLastPublishedTime(date)
		}
	}, [data, currentLastPublishedDate])

	const handleSave = async () => {
		toggleLoading()
		try {
			let datetime = lastPublishedDate
			datetime.set({ hour: lastPublishedTime.get("hour"), minute: lastPublishedTime.get("minute"), seconds: 0 })
			dispatch(setEpisodeLastPublishedDate(moment.tz(datetime, "GMT").format("ddd, DD MMM YYYY HH:mm:ss ZZ"))) // Formatting to RFC2822 for RSS purposes.
			await updateEpisode({
				...data?.episode,
				lastPublishedDate: moment(datetime)
				// lastPublishedDate: moment.tz(datetime, "GMT").format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
			})
			await publishEpisode({
				podcastId: selectedPodcast?.id,
				episodeId
			})
			dispatch(toggleChangeLastPublishedDateModalVisible())
			dispatch(setEpisodeIdForLastPublishedDateModal(null))
			toggleLoading()
			successAlert("Succcessfully updated publishing date.")
			queryClient.invalidateQueries(["episodes", data.episode.podcastId])
			queryClient.invalidateQueries(["episode", episodeId])
		} catch (e) {
			toggleLoading()
			errorAlert("Sorry, we were unable to update your publishing date.")
		}
	}

	const handleCancel = () => {
		setLastPublishedDate(null)
		setLastPublishedTime(null)
		dispatch(setEpisodeIdForLastPublishedDateModal(null))
		dispatch(toggleChangeLastPublishedDateModalVisible())
	}

	if (isLoading) {
		return (
			<PodpalModal>
				<p>Loading...</p>
			</PodpalModal>
		)
	} else if (isError) {
		return (
			<PodpalModal>
				<p>Error loading release date.</p>
			</PodpalModal>
		)
	}

	return (
		<StyledPodpalModal
			centered
			className="change-release-date-modal"
			visible={visible}
			title="Change Publishing Date"
			okText="Save"
			onOk={handleSave}
			onCancel={handleCancel}
			width={420}
			footer={[
				<PodpalButton key="cancel" variant="secondary" onClick={handleCancel}>
					Cancel
				</PodpalButton>,
				<PodpalButton key="confirm" type="primary" onClick={handleSave} loading={loading}>
					Confirm
				</PodpalButton>
			]}
		>
			<Row justify="center" align="middle" gutter={[0, 16]}>
				<Alert
					banner
					type="error"
					message="Please note that backdating your episode can impact episode ordering on podcast platforms like Apple Podcasts."
					style={{ minWidth: "420px" }}
					icon={<InfoCircleFilled />}
				/>
			</Row>
			<Form onFinish={handleSave}>
				<Form.Item>
					<Row gutter={[0, 12]}>
						<Col>
							<p>Please select the publishing date and time for this episode.</p>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={14}>
							<DatePicker
								disabledDate={current => {
									return current && current > moment()
								}}
								style={{ width: "100%" }}
								value={lastPublishedDate}
								onChange={(time, timeString) => {
									setLastPublishedDate(time)
								}}
								size="large"
								format="dddd, M/D/YYYY"
							/>
						</Col>
						<Col span={10}>
							<TimePicker
								style={{ width: "100%" }}
								use12Hours
								format="h:mm A"
								value={lastPublishedTime}
								onChange={(time, timeString) => {
									setLastPublishedTime(time)
								}}
								size="large"
							/>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</StyledPodpalModal>
	)
}

export default ChangeLastPublishedDateModal
