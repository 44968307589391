export const secondsToHoursAndMinutes = numSeconds => {
	const hours = Math.floor(numSeconds / 3600)
	const minutes = Math.floor((numSeconds % 3600) / 60)
	const seconds = Math.floor(numSeconds % 60)
	return {
		hours,
		minutes,
		seconds
	}
}

export const formatTime = numSeconds => {
	const { hours, minutes, seconds } = secondsToHoursAndMinutes(numSeconds)

	if (hours > 0) {
		return `${hours}h ${minutes}m`
	}

	if (minutes > 0) {
		return `${minutes}m ${seconds}s`
	}

	return `${seconds} seconds`
}

export const formatLastUpdatedTime = seconds => {
	if (seconds < 60) {
		return `${Math.floor(seconds)} second${Math.floor(seconds) !== 1 ? "s" : ""} ago`
	} else if (seconds / 60 < 60) {
		return `${Math.floor(seconds / 60)} minute${Math.floor(seconds / 60) !== 1 ? "s" : ""} ago`
	} else if (seconds / 3600 < 24) {
		return `${Math.floor(seconds / 3600)} hour${Math.floor(seconds / 3600) !== 1 ? "s" : ""} ago`
	} else {
		return `${Math.floor(seconds / (3600 * 24))} day${Math.floor(seconds / (3600 * 24)) !== 1 ? "s" : ""} ago`
	}
}
