import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { EpisodeProgress } from "./episodeProgress.service"
import { Operators, PropertyTypes, QueryOptions } from "./types/queryOptions"
import { Recording } from "./types/recording"

export enum EpisodeType {
	full = " FULL",
	trailer = "TRAILER",
	bonus = "BONUS"
}

export enum TrailerType {
	showTrailer = "SHOW_TRAILER",
	seasonTrailer = "SEASON_TRAILER",
	episodeTrailer = "EPISODE_TRAILER"
}

export enum BonusType {
	showBonus = "SHOW_BONUS",
	seasonBonus = "SEASON_BONUS",
	episodeBonus = "EPISODE_BONUS"
}

export enum MigrationStatus {
	migrationNone = "MIGRATION_NONE",
	migrationPending = "MIGRATION_PENDING",
	migrationLoading = "MIGRATION_LOADING",
	migrationError = "MIGRATION_ERROR",
	migrationSuccess = "MIGRATION_SUCCESS"
}

export interface Episode {
	id: string
	podcastId: string
	photoUrl: string
	title: string
	seasonNumber: number
	episodeNumber: number
	label?: string
	bonusType?: BonusType
	parentalAdvisory: string
	scheduledReleaseDate?: string
	lastPublishedDate?: string
	duration?: number
	showNotes?: string
	isDeleted: boolean
	itunesVisibility?: string
	isPublished: boolean
	showNotesUpdatedAt?: string
	publishedEpisodeModified?: boolean
	migrationStatus: MigrationStatus
	rssGuid?: string
	episodeType: EpisodeType
	trailerType: TrailerType
	plannedReleaseDate: string
	migrationErrorMessage: string
}

export interface FindEpisodeResponse {
	requestId: string
	episode: Episode
}

export interface EpisodesWithRecordings {
	episode: Episode
	recording: Recording
}

export enum EpisodeProgressKind {
	all = "0",
	inWork = "1",
	complete = "2",
	published = "3"
}

export interface FindPublishedEpisodesByPodcastIDRequest {
	requestId: string
	episodesWithRecordings: EpisodesWithRecordings[]
}

export interface CreateEpisodeProps extends Episode {
	thumbnailFile?: string
	recording?: string
}

export const createEpisode = ({ thumbnailFile, recording, ...data }: CreateEpisodeProps) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Create", {
		requestId: uuidv4(),
		episode: data,
		thumbnailFile: thumbnailFile,
		recording: recording
	})

export interface FindEpisodesProps {
	queryOptions?: QueryOptions
}

export interface FindEpisodesResponse {
	requestId: string
	episodes: Episode[]
}
export const findEpisodes = ({ queryOptions }: FindEpisodesProps) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Find", {
		requestId: uuidv4(),
		queryOptions
	})
export const findPublishedEpisodesByPodcastId = (podcastId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/FindPublishedByPodcastID", {
		requestId: uuidv4(),
		podcastId
	})

export const findEpisodeById = (episodeId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Find", {
		requestId: uuidv4(),
		queryOptions: {
			filters: [
				{
					propertyName: "ID",
					propertyValue: episodeId,
					propertyType: PropertyTypes.string,
					operator: Operators.equal
				}
			],
			limit: 1
		}
	})

export interface UpdateEpisodeProps extends Episode {
	thumbnailFile?: string
	recording?: string
}

export const updateEpisode = ({ thumbnailFile, recording, ...data }: UpdateEpisodeProps) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Update", {
		requestId: uuidv4(),
		episodeId: data.id,
		episode: data,
		thumbnailFile: thumbnailFile,
		recording: recording
	})

export interface PublishEpisodeProps {
	podcastId: string
	episodeId: string
}
export const publishEpisode = ({ podcastId, episodeId }: PublishEpisodeProps) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Publish", {
		podcastId,
		episodeId
	})

export const deleteEpisode = (episodeId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeService/Delete", {
		requestId: uuidv4(),
		episodeId
	})

interface FindEpisodesWithProgressProps {
	kind: string
	queryOptions: QueryOptions
}

export interface EpisodeWithProgress {
	episode: Episode
	episodeProgress: EpisodeProgress
}

export interface FindEpisodesWithProgressResponse {
	episodesWithProgress: EpisodeWithProgress[]
	queryMeta: {
		cursor: string
	}
	requestId: string
}

export function findEpisodesWithProgress(props: FindEpisodesWithProgressProps) {
	return axios.post("/api/twirp/podpal.administration.EpisodeService/FindWithProgress", {
		...props
	})
}
