/**
 * Input field names
 */
export const LABEL_NAMES = {
	PHOTO: "Show Artwork",
	TITLE: "Title",
	SUBTITLE: "Subtitle",
	SHOW_SUMMARY: "Show Summary",
	PROFILE_URL: "Podcast Profile URL",
	MEDIA_FORMAT: "Media Format",
	PARENTAL_ADVISORY: "Parental Advisory",
	UPDATE_STATUS: "Podcast Status",
	CATEGORIES: "Categories",
	LANGUAGE: "Language",
	KEYWORDS: "Keywords",
	EXTERNAL_WEBSITE: "Podcast Website (External)",
	PODCAST_EMAIL: "Podcast Email",
	ITUNES_VISIBILITY: "Podcast Visibility",
	COUNTRY: "Country/Region",
	TIME_ZONE: "Time Zone",
	SHOW_TYPE: "Show Type",
	EPISODE_GROUPING: "Episode Grouping",
	EPISODE_NUMBERING: "Episode Numbering",
	AUTO_SCHEDULE: "Auto-Schedule",
	DEFAULT_RELEASE_CADENCE: "Default Release Cadence",
	DEFAULT_RELEASE_DAY: "Default Release Day",
	DEFAULT_RELEASE_TIME: "Default Release Time",
	AUTO_PUBLISH: "Auto-Publish",
	AUTHORS: "Author(s)",
	COPYRIGHT: "Copyright",
	OWNER_NAME: "Owner Name",
	OWNER_EMAIL: "Owner Email",
	COLOR_PICKER: "Podcast Profile Color",
	AUDIO_BIO: "Podcast Audio Bio"
}

export const FIELD_NAMES = {
	PHOTO: "photoUrl",
	TITLE: "title",
	SUBTITLE: "subtitle",
	SHOW_SUMMARY: "showSummary",
	PROFILE_URL: "profileUrl",
	MEDIA_FORMAT: "mediaFormat",
	PARENTAL_ADVISORY: "parentalAdvisory",
	UPDATE_STATUS: "updateStatus",
	CATEGORIES: "categories",
	LANGUAGE: "language",
	KEYWORDS: "keywords",
	EXTERNAL_WEBSITE: "externalWebsite",
	PODCAST_EMAIL: "podcastEmail",
	ITUNES_VISIBILITY: "itunesVisibility",
	COUNTRY: "countryRegion",
	TIME_ZONE: "timeZone",
	SHOW_TYPE: "showType",
	EPISODE_GROUPING: "episodeGrouping",
	EPISODE_NUMBERING: "episodeNumbering",
	AUTO_SCHEDULE: "autoScheduleOn",
	DEFAULT_RELEASE_CADENCE: "defaultReleaseCadence",
	DEFAULT_RELEASE_DAY: "defaultReleaseDay",
	DEFAULT_RELEASE_TIME: "defaultReleaseTime",
	AUTO_PUBLISH: "autoPublishOn",
	AUTHORS: "authors",
	COPYRIGHT: "copyright",
	OWNER_NAME: "ownerName",
	OWNER_EMAIL: "ownerEmail",
	COLOR_PICKER: "color",
	AUDIO_BIO: "audioBioFile",
	OLD_HOST: "oldHost",
	SCHEDULED_RELEASE_DATE: "scheduledReleaseDate",
	PLANNED_RELEASE_DATE: "plannedReleaseDate"
}

export const MEDIA_FORMAT_OPTIONS = {
	AUDIO: "audio",
	VIDEO: "video"
}

export const PARENTAL_ADVISORY_OPTIONS = {
	CLEAN: "clean",
	EXPLICIT: "explicit"
}

export const UPDATE_STATUS_OPTIONS = {
	ACTIVE: "active",
	COMPLETE: "complete"
}

export const ITUNES_VISIBILITY_OPTIONS = {
	SHOW: "show",
	HIDE: "hide"
}

export const SHOW_TYPE_OPTIONS = {
	EPISODIC: "episodic",
	SERIAL: "serial"
}

export const EPISODE_GROUPING_OPTIONS = {
	NONE: "none",
	SEASONS: "seasons"
}

export const EPISODE_NUMBERING_OPTIONS = {
	YES: "yes",
	NO: "no"
}

export const PHOTO_TOO_LARGE_ERROR_MESSAGE = "Please choose an image less than 16MB."
export const TITLE_EMPTY_ERROR_MESSAGE = "Title cannot be empty."
export const TITLE_TOO_LONG_ERROR_MESSAGE = "Title cannot exceed 50 characters."
export const SUBTITLE_TOO_LONG_ERROR_MESSAGE = "Subtitle cannot exceed 250 characters."
export const SUBTITLE_EMPTY_ERROR_MESSAGE = "Subtitle cannot be empty."
export const SHOW_SUMMARY_TOO_LONG_ERROR_MESSAGE = "Show Summary cannot exceed 4000 characters."
export const SHOW_SUMMARY_EMPTY_ERROR_MESSAGE = "Show Summary cannot be empty."
export const PROFILE_URL_INVALID_ERROR_MESSAGE = "Your custom URL does not meet the requirements."
export const PROFILE_URL_EMPTY_ERROR_MESSAGE = "Please enter your profile URL."
export const PROFILE_URL_TAKEN_ERROR_MESSAGE = "Sorry, this Profile URL is taken."
export const CATEGORY_UNSELECTED_ERROR_MESSAGE = "Please select a category."
export const CATEGORY_TOO_MANY_SELECTED_ERROR_MESSAGE = "Please select a maximum of three categories."
export const LANGUAGE_UNSELECTED_ERROR_MESSAGE = "Please select a language."
export const KEYWORDS_TOO_MANY_ADDED_ERROR_MESSAGE = "Please add a maximum of 25 keywords."
export const COUNTRY_UNSELECTED_ERROR_MESSAGE = "Please select a location."
export const TIME_ZONE_UNSELECTED_ERROR_MESSAGE = "Please select a time zone."
export const EXTERNAL_WEBSITE_INVALID_URL_ERROR_MESSAGE = "Please enter a valid URL."
export const EMAIL_INVALID_ERROR_MESSAGE = "Please enter a valid email address."
export const TOO_MANY_AUTHORS_ERROR_MESSAGE = "Please add a maximum of 10 authors."
export const OWNER_NAME_TOO_SHORT_ERROR_MESSAGE = "Name must be at least 2 characters."
export const DEFAULT_RELEASE_TIME_EMPTY_MESSAGE = "Default release time is required."
export const PARENTAL_ADVISORY_REQUIRED = "Parental Advisory is required."
export const MEDIA_FORMAT_REQUIRED = "Media format is required."
export const AUTHORS_REQUIRED = "Specify the person or organization who authored this podcast."
export const OWNER_NAME_REQUIRED = "Name is required."
export const OWNER_EMAIL_REQUIRED = "Owner email is also required."
export const COPYRIGHT_REQUIRED = "Copyright for the podcast is required."
