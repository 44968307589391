import React, { useRef } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import styled from "styled-components"
import "./index.scss"

// Register Quill classes/styles/attributes so they work without quill css dependencies
const Parchment = Quill.import("parchment")
class IndentAttributor extends Parchment.Attributor.Style {
	add(node, value) {
		if (value === 0) {
			this.remove(node)
			return true
		} else {
			return super.add(node, `${value}em`)
		}
	}
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
	scope: Parchment.Scope.BLOCK,
	whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
})

Quill.register(IndentStyle, true)

var DirectionAttribute = Quill.import("attributors/attribute/direction")
Quill.register(DirectionAttribute, true)

var AlignClass = Quill.import("attributors/class/align")
Quill.register(AlignClass, true)

var DirectionClass = Quill.import("attributors/class/direction")
Quill.register(DirectionClass, true)

var AlignStyle = Quill.import("attributors/style/align")
Quill.register(AlignStyle, true)

var DirectionStyle = Quill.import("attributors/style/direction")
Quill.register(DirectionStyle, true)

const formats = ["bold", "italic", "underline", "align", "blockquote", "list", "bullet", "indent", "link"]
const modules = {
	toolbar: [
		["bold", "italic", "underline"],
		[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
		// [
		// 	{
		// 		align: [
		// 			// defaults from theme
		// 		],
		// 	},
		// ],
		["link"]
	]
}

const StyledReactQuill = styled(ReactQuill).attrs(props => ({
	maxHeight: props.height || ""
}))`
	height: ${props => props.height};
	max-height: ${props => props.height};
	.ql-toolbar {
		border: 1px solid #d9d9d9;
		border-bottom: none;
		border-radius: 4px 4px 0px 0px;

		.ql-formats button {
			color: #000000;
		}
	}
	.ql-container {
		overflow: scroll !important;
		border: 1px solid #d9d9d9 !important;
		border-radius: 0px 0px 4px 4px;
		transition: border 0.3s ease-in-out;
		&:hover {
			border: 1px solid #789aff !important;
		}
	}
`

const RichTextEditor = ({ defaultValue, setValue, setLength, placeholder, height }) => {
	const reactQuillRef = useRef(null)

	const handleChange = content => {
		setValue(content)
	}

	return (
		<div className="editor">
			<StyledReactQuill
				ref={el => {
					reactQuillRef.current = el
					setLength(reactQuillRef?.current?.getEditor().getLength() - 1) // This -1 seems necessary but I'm not sure why... Thanks Quill >:(
				}}
				theme="snow"
				placeholder={placeholder}
				defaultValue={defaultValue}
				height={height}
				onChange={handleChange}
				modules={modules}
				formats={formats}
			/>
		</div>
	)
}

export default RichTextEditor
